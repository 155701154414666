import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

import { environment, SequencingConfig } from '@environments/environment';
import { AppConfigService } from 'shared';
import { PageSequence } from '@app/_models/page-sequencing/angular/page-sequence';
import { Page } from '@app/_models/page-sequencing/angular/page';
import { PageSequenceMVC } from '@app/_models/page-sequencing/KePages/page-sequence-mvc';
import { PageMVC } from '@app/_models/page-sequencing/KePages/page-mvc';
import { ServiceResultPageSequence } from '@app/_models/page-sequencing/angular/service-result-page-sequence';
import { FlagMVC } from '@app/_models/page-sequencing/KePages/flag-mvc';
import { ProximityMVC } from '@app/_models/page-sequencing/KePages/proximity-mvc';
import { Proximity } from '@app/_models/page-sequencing/angular/proximity';
import { MediaSetMvc } from '@app/_models/page-sequencing/media/media-set-mvc';
import { MessageMvc } from '@app/_models/page-sequencing/KePages/message-mvc';
import { Flag } from '@app/_models/page-sequencing/angular/flag';
import { associatedItem } from '@app/_models/page-sequencing/angular/associatedItem';
import { FlagRestriction } from '@app/_models/page-sequencing/angular/flag-restrictions';
import { FlagTypeMVC } from '@app/_models/page-sequencing/KePages/flag-types-mvc';
import { TargetPageMVC } from '@app/_models/page-sequencing/KePages/target-pages-mvc';
import { SequencingConfiguration } from '@app/_models/page-sequencing/angular/sequencing-config';
import { Rule } from '@app/_models/page-sequencing/KePages/rule';
import { RuleMvc } from '@app/_models/page-sequencing/KePages/rule-mvc';


@Injectable({ providedIn: 'root' })
export class PageSequencingService {

    configImageThumbnailWidthPixels: number = 0;
    constructor(private http: HttpClient, private appConfigService: AppConfigService) {
    }

    async tokenHeaders(params?: any) {
        var options = {};

        if (params) {
            options = {
                ...options,
                params: params
            };
        }

        let token: string = sessionStorage.getItem("keycloakToken");
        if (token != undefined) {
            options = {
                ...options,
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            };
        }

        console.log("tokenHeaders = " + JSON.stringify(options));

        return options;
    }

    async postHeaders() {
        // Headers for post request
        //let token: string = await this.keycloak.getToken();
        var headers = {};

        headers = {
            ...headers,
            "Content-Type": "application/json"
        };

        let token: string = sessionStorage.getItem("keycloakToken");
        if (token != undefined) {
            headers = {
                ...headers,
                "Authorization": "Bearer " + token
            };
        }

        console.log("postHeaders = " + JSON.stringify(headers));

        return {
            headers: new HttpHeaders(headers)
        };
    }

    async getPageSequences(): Promise<PageSequence[]> {
        let pageSequencesMVC: PageSequenceMVC[] = await this.http.get<PageSequenceMVC[]>(`${environment.cmtApiUrl}/sequence/getSequences`, await this.tokenHeaders()).toPromise();
        console.log("Page sequences from api: PageSequences = " + JSON.stringify(pageSequencesMVC));
        let pageSequences: Array<PageSequence>;
        pageSequences = [];
        for (var i = 0; i < pageSequencesMVC.length; i++) {
            pageSequences.push({ sequenceId: pageSequencesMVC[i].sequenceId, name: pageSequencesMVC[i].name, isDeleted: false, isEdited: false, pageOrderChanged: false });
        }
        console.log("PageSequences from PageSequence service: PageSequences = " + JSON.stringify(pageSequences));
        return pageSequences;
    }

    async getPagesForPageSequence(pageSequenceId: string): Promise<Page[]> {
        let pagesX = await this.http.get<PageMVC[]>(`${environment.cmtApiUrl}/sequence/getPages/${pageSequenceId}`, { headers: await this.tokenHeaders(), observe: 'response' }).pipe(
            map((res) => {
                if (res.status == 204) {
                    throw 204
                } else if (res.status == 200) {
                    return res.body
                }
            }), catchError(error => { throw error })
        ).toPromise();
        console.log("Media Items for media set from api: media items = " + JSON.stringify(pagesX));


        let pages: Array<Page>;
        pages = [];
        for (var i = 0; i < pagesX.length; i++) {
            let flag: Flag = null;
            if (pagesX[i].flag != null) {
                flag = {
                    flagID: pagesX[i].flag.flagID,
                    condition: pagesX[i].flag.condition,
                    text: pagesX[i].flag.text,
                    value: pagesX[i].flag.value,
                    isDeleted: false,
                    isEdited: false,
                    isNew: false
                };
            }
            let proximity: Proximity = null;
            if (pagesX[i].proximity != null) {
                proximity = {
                    proximityId: pagesX[i].proximity.proximityId,
                    proximityDestinationFlag: pagesX[i].proximity.proximityDestinationFlag,
                    stations: pagesX[i].proximity.stations,
                    time: pagesX[i].proximity.time,
                    isDeleted: false,
                    isEdited: false,
                    isNew: false
                };
            }
            //Convert from seconds to minutes
            pagesX[i].repeatTime = Math.trunc(pagesX[i].repeatTime/60);

            let pushItem: Page = {
                pageDataId: pagesX[i].pageDataId,
                associatedData: pagesX[i].associatedData,
                name: pagesX[i].name,
                enabled: pagesX[i].enabled,
                displayDuration: pagesX[i].displayDuration,
                pageType: pagesX[i].pageType,
                sequencePosition: pagesX[i].sequencePosition,
                flag: flag,
                repeatTime: pagesX[i].repeatTime,
                isNew: false,
                isDeleted: false,
                proximity: proximity,
                isEdited: false,
                isReadOnly: pagesX[i].isReadOnly,
                isHovered: false,
                itemFound: null,
                itemWarning: null
            };

            pages.push(pushItem);
        }

        console.log("Pages for page PageSequence from page PageSequence service: pages = " + JSON.stringify(pages));

        //bubble sort to order the pages by there sequence position
        for (let i = 0; i < pages.length; i++) {
            for (let j = 0; j < pages.length - 1; j++) {
                if (pages[j].sequencePosition > pages[j + 1].sequencePosition) {
                    let swap = pages[j];
                    pages[j] = pages[j + 1];
                    pages[j + 1] = swap;
                }
            }
        }
        return pages;
    }

    async savePageSequence(sequenceId: string, name: string, isEdited: boolean, creatorEmail: string, pages: Page[], orderChanged: boolean): Promise<ServiceResultPageSequence> {
        let result: ServiceResultPageSequence = new ServiceResultPageSequence();
        result.success = true;
        result.errorMessage = '';
        let isCreate: boolean = false;
        let createUpdateRequest: PageSequenceMVC = new PageSequenceMVC();
        //The number 36 is used because its the length of a valid GUID
        if (sequenceId != null && sequenceId.length != 36) {
            isCreate = true;
        } else {
            createUpdateRequest.sequenceId = sequenceId;
        }
        createUpdateRequest.name = name;
        createUpdateRequest.pages = Array<Page>();
        createUpdateRequest.creatorEmail = creatorEmail;
        createUpdateRequest.pageOrderChanged = orderChanged;
        if (isCreate) {
            createUpdateRequest.sequenceId = null;
            for (var i = 0; i < pages.length; i++) {
                var flag = null;
                if (pages[i].flag != null) {
                    let tempFlag: FlagMVC = {
                        flagID: null,
                        condition: pages[i].flag.condition,
                        value: pages[i].flag.value.toString(),
                        text: pages[i].flag.text,
                        isDeleted: false
                    }
                    flag = tempFlag;
                }
                var proximity = null;
                if (pages[i].proximity != null) {
                    let tempProximity: ProximityMVC = {
                        proximityId: null,
                        proximityDestinationFlag: pages[i].proximity.proximityDestinationFlag,
                        stations: pages[i].proximity.stations,
                        time: pages[i].proximity.time,
                        isDeleted: false
                    }
                    proximity = tempProximity;
                }
                //The number 36 is used because its the length of a valid GUID
                if (pages[i].pageDataId != null && pages[i].pageDataId.length != 36) {
                    pages[i].pageDataId = null;
                }
                createUpdateRequest.pages.push({
                    pageDataId: null,
                    associatedData: pages[i].associatedData,
                    name: pages[i].name,
                    displayDuration: pages[i].displayDuration,
                    enabled: pages[i].enabled,
                    sequencePosition: pages[i].sequencePosition,
                    pageType: pages[i].pageType,
                    flag: flag,
                    proximity: proximity,
                    repeatTime: pages[i].repeatTime,
                    isReadOnly: pages[i].isReadOnly,
                    isDeleted: pages[i].isDeleted
                });
            }
        } else {
            for (var i = 0; i < pages.length; i++) {
                var flag = null;
                if (pages[i].flag != null) {
                    let tempFlag: FlagMVC = {
                        flagID: pages[i].flag.flagID,
                        condition: pages[i].flag.condition,
                        value: pages[i].flag.value.toString(),
                        text: pages[i].flag.text,
                        isDeleted: pages[i].flag.isDeleted,
                    }
                    if (pages[i].flag.flagID == null) {
                        tempFlag.flagID == null;
                    }
                    flag = tempFlag;
                }
                var proximity = null;
                if (pages[i].proximity != null) {
                    let tempProximity: ProximityMVC = {
                        proximityId: pages[i].proximity.proximityId,
                        proximityDestinationFlag: pages[i].proximity.proximityDestinationFlag,
                        stations: pages[i].proximity.stations,
                        time: pages[i].proximity.time,
                        isDeleted: pages[i].proximity.isDeleted,
                    }
                    proximity = tempProximity;
                }
                if (pages[i].pageDataId != null && pages[i].pageDataId.length != 36) {
                    pages[i].pageDataId = null;
                }
                createUpdateRequest.pages.push({
                    pageDataId: pages[i].pageDataId,
                    associatedData: pages[i].associatedData,
                    name: pages[i].name,
                    displayDuration: pages[i].displayDuration,
                    enabled: pages[i].enabled,
                    sequencePosition: pages[i].sequencePosition,
                    pageType: pages[i].pageType,
                    flag: flag,
                    proximity: proximity,
                    repeatTime: pages[i].repeatTime,
                    isReadOnly: pages[i].isReadOnly,
                    isDeleted: pages[i].isDeleted
                });
            }
        }
        for (var page = 0; page < pages.length; page++) {
            if (pages[page].flag != null) {
                pages[page].flag.value = pages[page].flag.value.toString();
            }
            if (pages[page].pageDataId == null || pages[page].pageDataId.length != 36) {
                pages[page].pageDataId = null;
            }
        }
        console.log(createUpdateRequest);
        let createUpdateRequestJson: string = JSON.stringify(createUpdateRequest);
        console.log("createUpdateRequestJson = " + createUpdateRequestJson);
        if (isCreate) {
            let newPageSequenceId: string = await this.http.post<any>(`${environment.cmtApiUrl}/sequence/createSequence`, createUpdateRequestJson, await this.postHeaders())
                .pipe(
                    catchError(error => {
                        if (error.error instanceof ErrorEvent) {
                            result.errorMessage = error.error.message;
                            console.log("savePagePageSequence: Error: " + error.error.message);
                        } else {

                            // error.message gives us back back 'Http failure response for https://localhost:5001/media/upload: 415 OK' or similar from the server,
                            // whatever we try to send back as error _text_ from there, which is confusing as best!
                            result.errorMessage = error.status;

                            console.log("savePagePageSequence: Error - " + error.status + " : " + error.message);
                            result.success = false;
                        }
                        return of(undefined);
                    })
                ).toPromise();
            result.id = newPageSequenceId;  // Pass new media set id back to component
            console.log("savePagePageSequence(mvc): result = " + JSON.stringify(result));
            return result;
        }
        else {
            let success: boolean = await this.http.post<any>(`${environment.cmtApiUrl}/sequence/updateSequence`, createUpdateRequestJson, await this.postHeaders())
                .pipe(
                    catchError(error => {
                        if (error.error instanceof ErrorEvent) {
                            result.errorMessage = error.error.message;
                            console.log("savePagePageSequence: Error: " + error.error.message);
                        } else {
                            // error.message gives us back back 'Http failure response for https://localhost:5001/media/upload: 415 OK' or similar from the server,
                            // whatever we try to send back as error _text_ from there, which is confusing as best!
                            result.errorMessage = error.status;

                            console.log("savePagePageSequence: Error - " + error.status + " : " + error.message);
                        }
                        return of(false);
                    })
                ).toPromise();
            result.success = result.success && success;
        }

        // eventually here: rules for media set

        console.log("savePagePageSequence(mvc): result = " + JSON.stringify(result));

        return result;
    }

    async deletePageSequence(PageSequenceId: string): Promise<ServiceResultPageSequence> {
        let result: ServiceResultPageSequence = new ServiceResultPageSequence();
        result.success = true;
        result.errorMessage = '';
        result.id = "";
        let deleteJson: string = JSON.stringify(PageSequenceId.toString());
        let success: boolean = await this.http.post<any>(`${environment.cmtApiUrl}/sequence/deleteSequence`, deleteJson, await this.postHeaders()).pipe(
            catchError(error => {
                if (error.error instanceof ErrorEvent) {
                    result.errorMessage = error.error.message;
                    console.log("delete page sequence: Error: " + error.error.message);
                } else {
                    result.errorMessage = error.status;

                    console.log("delete page sequence: Error - " + error.status + " : " + error.message);
                }
                return of(false);
            })
        ).toPromise();;
        result.success = success;
        return result
    }

    async getMediaSets(): Promise<associatedItem[]> {
        let mediaSetsX: MediaSetMvc[] = [];
        mediaSetsX = await this.http.get<MediaSetMvc[]>(`${environment.cmtApiUrl}/media/mediasets`, await this.tokenHeaders()).toPromise();
        console.log("Media Sets from api: media sets = " + JSON.stringify(mediaSetsX));
        let mediaSets: associatedItem[] = [];
        for (var i = 0; i < mediaSetsX.length; i++) {
            if (mediaSetsX[i].enabled) {
                mediaSets.push({
                    id: mediaSetsX[i].mediaSetId,
                    name: mediaSetsX[i].name
                });
            }
        }
        console.log("Media Sets from media service: media sets = " + JSON.stringify(mediaSets));
        return mediaSets;
    }

    async getMessages(): Promise<associatedItem[]> {
        let messagesX: MessageMvc[] = [];
        messagesX = await this.http.get<MessageMvc[]>(`${environment.cmtApiUrl}/message/messages`, await this.tokenHeaders()).toPromise();
        console.log("Messages from api: messages = " + JSON.stringify(messagesX));
        let messages: associatedItem[] = [];
        //messages = ["Message 1", "Message 2", "Message 3"];
        //messages = ["Message 1", "Message 2", "Message 3"];
        for (var i = 0; i < messagesX.length; i++) {
            if (!messagesX[i].isTemplate) {
                messages.push({
                    id: messagesX[i].messageId,
                    name: messagesX[i].name
                });
            }
        }
        console.log("Messages from message service: messages = " + JSON.stringify(messages));
        return messages;
    }


    async getRules(): Promise<Rule[]> {

        let rulesX: RuleMvc[] = await this.http.get<RuleMvc[]>(`${environment.cmtApiUrl}/rules/rules`, await this.tokenHeaders())
            .pipe(
                catchError(error => {
                    if (error.error instanceof ErrorEvent) {
                        console.log("saveRule: Error: " + error.error.message);
                    } else {
                        console.log("saveRule: Error - " + error.status + " : " + error.message);
                    }
                    return of([]);
                })
            ).toPromise();
        console.log("Rules from api: rules = " + JSON.stringify(rulesX));

        let rules: Array<Rule>;

        rules = [];
        for (var i = 0; i < rulesX.length; i++) {
            rules.push({
                id: rulesX[i].id,
                description: rulesX[i].description,
                runDays: rulesX[i].runDays,
                headcodes: rulesX[i].headcodes,
                location: rulesX[i].location,
                locationActivity: rulesX[i].locationActivity,
                serviceState: rulesX[i].serviceState,
                dateTimeRanges: rulesX[i].dateTimeRanges,
                runTimes: rulesX[i].runTimes,
                matchPenultimateStation: rulesX[i].matchPenultimateStation,
                deviceGroup: rulesX[i].deviceGroup,
                stationGroup: rulesX[i].stationGroup,
                unitNumbers: rulesX[i].unitNumbers,
                pageSequence: rulesX[i].pageSequence,
                order: rulesX[i].order,
                isEnabled: rulesX[i].isEnabled,
                isPublished: rulesX[i].isPublished,
                isDefaultRule: rulesX[i].isDefaultRule,
                isDeleted: false
            });
        }

        console.log("Rules from rule service: rules = " + JSON.stringify(rules));

        return rules;
    }

    async getStations(): Promise<associatedItem[]> {
        let stations: associatedItem[] = [];
        try {
            stations = await this.http.get<associatedItem[]>(`${environment.cmtApiUrl}/sequence/stations`, await this.tokenHeaders()).toPromise();
            console.log("stations from api: " + JSON.stringify(stations));
        } catch (error) {
            console.log(error)
        }
        return stations;
    }

    async getFlagData(): Promise<FlagTypeMVC[]> {
        let flags: FlagTypeMVC[] = [];
        try {
            flags = await this.http.get<FlagTypeMVC[]>(`${environment.cmtApiUrl}/sequence/getFlagTypes`, await this.tokenHeaders()).toPromise();
        } catch (error) {
            console.log(error)
        }
        console.log(JSON.stringify(flags) + "33");
        return flags;
    }

    async getTargetPages(flags: FlagTypeMVC[]): Promise<TargetPageMVC[]> {
        let targetPages: TargetPageMVC[] = [];
        var targetPageOut = []
        try {
            targetPages = await this.http.get<TargetPageMVC[]>(`${environment.cmtApiUrl}/sequence/getTargetPages`, await this.tokenHeaders()).toPromise();
            for (var targetPage of targetPages) {
                if (this.flagExists(targetPage.text, flags)) {
                    targetPageOut.push({
                        id: targetPage.id,
                        name: targetPage.name,
                        condition: targetPage.condition,
                        text: "Page is shown when " + targetPage.text + " occurs", //this text is used for hover over flag
                        value: targetPage.value
                    });
                }
                else {
                    targetPageOut.push({
                        id: targetPage.id,
                        name: targetPage.name,
                        condition: "",
                        text: "Page is always shown",
                        value: 0
                    });
                }
            }
        } catch (error) {
            console.log(error)
        }
        return targetPageOut;
    }

    getFlagRestrictions(flags: FlagTypeMVC[]) {
        let flagRestriction: FlagRestriction[] = [];
        for (var flag of flags) {
            flagRestriction.push({
                name: flag.name,
                restriction: flag.restriction
            });
        }
        return flagRestriction;
    }

    getFlagNames(flags: FlagTypeMVC[]) {
        let names: string[] = [];
        for (var flag of flags) {
            names.push(flag.name);
        }
        return names;
    }


    flagExists(name, flags: FlagTypeMVC[]) {
        for (var flag of flags) {
            if (flag.name == name) {
                return true;
            }
        }
        return false;
    }

    async retrieveConfig() {
        var config = SequencingConfig;
        var stations = await this.getStations();
        var flagData = await this.getFlagData();
        var targetPages = await this.getTargetPages(flagData);
        var flagNames = await this.getFlagNames(flagData);
        var flagRestrictions = await this.getFlagRestrictions(flagData);
        console.log(JSON.stringify(flagData) + "33");
        let seqConfig: SequencingConfiguration = {
            sequenceMaxNameLength: config.pageSequenceNameMaxLength,
            defaultNewSequenceId: config.defaultNewSequenceId,
            mandatorySequence: config.mandatorySequence,
            typeOfMedia: config.typeOfMedia,
            maxNumberOfMediaSets: config.maxNumberOfMediaSets,
            defaultPageDisplayDuration: config.defaultPageDisplayDuration,
            keyPressTimeoutMs: config.keyPressTimeoutMs,
            stations: stations,
            flagTypes: config.typeOfFlagsTypes,
            flagNames: flagNames,
            flagRestrictions: flagRestrictions,
            targetPages: targetPages
        };
        return seqConfig;
    }


}
