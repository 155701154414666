import { Injectable } from '@angular/core';

import { environment } from '@environments/environment';
import { User } from '../_models/user';
import { KeycloakEvent, KeycloakEventType, KeycloakService } from 'keycloak-angular';

@Injectable({ providedIn: 'root' })
export class keycloakService extends KeycloakService {
    private currentUser: User;
    constructor() {
        console.log("Constructing keycloak service");
        super();
        this.registerEvents();

        window['keycloak_getToken'] = (() => this.getToken());
        window['keycloak_updateToken'] = (() => this.updateToken());
        window['keycloak_currentUser'] = (() => this.currentUserValue);
        window['keycloak_hasPermission'] = (p => this.hasPermission(p));
    }

    public get currentUserValue(): User {

        var keycloakInstance = this.getKeycloakInstance();
        if (keycloakInstance != undefined) {
            var token = keycloakInstance.token;
            var profile = keycloakInstance.profile;

            if (token && profile) {
                this.currentUser = new User();
                this.currentUser.userName = profile.username;
                this.currentUser.firstName = profile.firstName;
                this.currentUser.lastName = profile.lastName;

                this.currentUser.token = token;

                this.currentUser.accessLevel = 1; //Unfinished
                this.currentUser.accessPermissions = this.getUserRoles().filter(permissions => permissions.includes('Permissions'));

                //Users without permission to access the CMT are logged out.
                if (this.currentUser.accessPermissions) {
                    if (!this.currentUser.accessPermissions.find(x => x == 'Permissions.CmtUser')) {
                        console.log("Insufficient permissions to access the CMT")
                        this.logout(environment.logoutUrl);
                        return undefined;
                    }
                }
                else {
                    console.log("Unable to retrieve user permissions")
                    this.logout(environment.logoutUrl);
                    return undefined;
                }
                return this.currentUser;
            }
        }
        else {
            console.log("Keycloak instance is not defined");
        }

        return undefined;
    }
        
    registerEvents(): void {
        this.keycloakEvents$.subscribe(async (ob: KeycloakEvent) => {
            // https://github.com/keycloak/keycloak-documentation/blob/master/securing_apps/topics/oidc/javascript-adapter.adoc
            if (ob.type === KeycloakEventType.OnTokenExpired) {
                console.log("Keycloak event: OnTokenExpired");
                this.updateToken(0);
                this.cacheToken();
            }
            if (ob.type === KeycloakEventType.OnAuthSuccess) {
                console.log("Keycloak event: OnAuthSuccess");
                this.cacheToken();
            }
            if (ob.type === KeycloakEventType.OnAuthRefreshSuccess) {
                console.log("Keycloak event: OnAuthRefreshSuccess");
                this.cacheToken();
            }
            if (ob.type === KeycloakEventType.OnAuthRefreshError) {
                console.log("Keycloak event: OnAuthRefreshError");
                sessionStorage.removeItem("keycloakToken");
                this.logout(environment.logoutUrl);
            }
        })
    }

    cacheToken() {
        this.getToken().then(token => {
            console.log("New keycloak token is " + token);
            sessionStorage.setItem("keycloakToken", token);
        });
    }

    hasPermission(permission: string): boolean {

        if (this.currentUserValue && typeof this.currentUserValue.accessPermissions !== 'undefined' && this.currentUserValue.accessPermissions.length > 0) {
            if (this.currentUserValue.accessPermissions.includes(permission)) {
                return true;
            }
            else {
                return false;
            }
        }   
        return false;
    }

    goToAdminConsole() {
        var link: string = environment.adminConsoleUrl;
        link = link.replace('#KEYCLOAKURL#', environment.keycloak.url).replace('#REALM#', environment.keycloak.realm);
        console.log("Keycloak account link = " + link);
        window.open(link, "_blank");
    }
}
