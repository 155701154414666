import { Flag } from "./flag";
import { Proximity } from "./proximity";

export class Page {
    pageDataId: string;
    name: string;
    associatedData: string;
    displayDuration: number;
    enabled: boolean;
    sequencePosition: number;
    pageType: string;
    flag: Flag;
    proximity: Proximity;
    repeatTime: number;
    isEdited: boolean
    isNew: boolean;
    isDeleted: boolean;
    isReadOnly: boolean;
    itemFound: boolean;
    itemWarning: boolean;
    isHovered: boolean;
}