import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormGroupDirective, ValidationErrors, AbstractControl } from '@angular/forms';
import { UserService } from '@app/_services';
import { MustMatch } from '@app/_helpers/must-match.validator';
import { PatternValidator } from '@app/_helpers/pattern-validator.validator';
import { PasswordUpdateRequest } from '@app/_models/password-update-request';
import { keycloakService } from '../../_services/keycloak.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CookieServiceUtil } from '../../_services/CookieServiceUtil';
import { environment } from '@environments/environment';

@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html',
})
export class ChangePasswordComponent {
    submitted = false;
    loading = false;
    updateSucceeded = false;
    updateFailed = false;
    response = '';
    timeoutString = "";
    interval;
    userId;
    userName = "val";
    emailRate;
    constructor(private formBuilder: FormBuilder, private userService: UserService, private keycloakService: keycloakService, private router: Router, private CookieService: CookieServiceUtil) {
        this.userName = this.keycloakService.getUsername();
    }

    async ngOnInit() {
        this.emailRate = environment.resetPasswordEmailRate;
        this.userName = this.keycloakService.getUsername();
        if (this.CookieService.GetCookie(this.userName) != "") {
            this.updateSucceeded = true;
            this.updateFailed = false;
            this.interval = setInterval(() => {
                var value = this.CookieService.GetCookie(this.userName);
                if (value == "") {
                    this.updateSucceeded = false;
                    this.updateFailed = false;
                    clearInterval(this.interval);
                }
            }, environment.disableResetPasswordRate);
        } else {
            clearInterval(this.interval);
        }
    }


    submit() {
        var userId = this.keycloakService.getKeycloakInstance().tokenParsed.sub;
        this.userService.updatePassword(userId, environment.changePasswordTime * 60).subscribe(success => {
            this.response = "An email has been sent to your email registered with your account!";
            this.updateSucceeded = true;
            this.updateFailed = false;
            var currentDate = new Date();
            var futureDate = new Date(currentDate.getTime() + environment.resetPasswordEmailRate * 60000);
            this.CookieService.AddCookieWithExp(this.userName, futureDate.toString(), environment.resetPasswordEmailRate);
            this.interval = setInterval(() => {
                var value = this.CookieService.GetCookie(this.userName);
                if (value == "") {
                    this.updateSucceeded = false;
                    this.updateFailed = false;
                    clearInterval(this.interval);
                }
            }, environment.disableResetPasswordRate);
        },
        error =>
        {
            this.response = error.error;
            this.updateSucceeded = false;
            this.updateFailed = true;
            this.loading = false;
        });        
    }

    returnToMainPage() {
        $('#backButton').click();
    }
}