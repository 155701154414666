import {
    Component,
    OnInit,
    ViewChild,
    ElementRef,
    ViewEncapsulation,
    QueryList,
    ViewChildren
} from "@angular/core";
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { User } from "../../_models/user";
import { HttpClient } from "@angular/common/http";
import { ApproveConfirmComponent, LoadingComponent, TaskResult } from 'shared';
import { AppConfigService } from 'shared';
import { environment } from "../../../environments/environment";
import { UserDetails } from "../../_models/user-details";
import { UserService } from "../../_services";
import { ServiceResultUser } from "../../_models/service-result-user";
import { keycloakService } from '@app/_services/keycloak.service';
import { Group } from "../../_models/group";

@Component({
    selector: "users",
    templateUrl: "./users.component.html",
    encapsulation: ViewEncapsulation.None
})
export class UsersComponent implements OnInit {
    @ViewChild(LoadingComponent, { static: false }) loadingSpinner: LoadingComponent;
    @ViewChildren(ApproveConfirmComponent) approveConfirmers: QueryList<ApproveConfirmComponent>;

    private appConfigService: AppConfigService;

    users: UserDetails[];
    groups: Group[];
    currentUser: User;
    originalUser: UserDetails;
    userService: UserService;
    userToDelete: UserDetails;
    showUsersOverview: boolean = true;
    showUserEditor: boolean = false;
    errorMessage: string = '';
    errorMessageOutput: string;
    enableSave: boolean;
    changesMade: boolean = false;

    usernameMaxLength = environment.nameMaxLength;
    emailMaxLength = environment.emailMaxLength;
    firstNameMaxLength = environment.firstNameMaxLength;
    lastNameMaxLength = environment.lastNameMaxLength;

    canCreateUser: boolean;

    //user actions
    userCreating: boolean;
    userUpdating: boolean;

    //User form
    userForm: FormGroup;
    get username() { return this.userForm.get('username').value; };
    get email() { return this.userForm.get('email').value; };
    get firstName() { return this.userForm.get('firstName').value; };
    get lastName() { return this.userForm.get('lastName').value; };
    get accessLevel() { return this.userForm.get('accessLevel').value; };

    readonly defaultId: string = "";
    readonly defaultUsername: string = "";
    readonly defaultEmail: string = "";
    readonly defaultFirstName: string = "";
    readonly defaultLastName: string = "";
    readonly defaultAccessLevel: string = "null";

    constructor(private httpClient: HttpClient, private keycloakService: keycloakService) {
        this.appConfigService = new AppConfigService(this.httpClient);
        this.userService = new UserService(this.httpClient);
        this.currentUser = this.keycloakService.currentUserValue;
        console.log("Constructing users overview");
        this.userCreating = false;
        this.userUpdating = false;
    }

    async ngOnInit() {
        console.log("Initialising user component");
        this.showUsersOverview = true;
        this.showUserEditor = false;

        this.canCreateUser = this.appConfigService.userHasPermission('Permissions.CanCreateUser');

        try {
            this.users = await this.userService.getAll();
            for (var i = 0; i < this.users.length; i++) {
                var userGroup = await this.userService.getUserGroup(this.users[i].id);
                if (userGroup.length != 0) {
                    this.users[i].accessLevel = userGroup[0].name;
                }
            }
            this.groups = await this.userService.getGroups();
        } catch (e) {
            this.users = null;
            if (e.error.instance == "No connection could be made because the target machine actively refused it.") {
                this.errorMessageOutput = "No connection to " + environment.targetSystem + " could be established.";
            } else {
                this.errorMessageOutput = "Error on the " + environment.targetSystem + " server.";
            }
            setTimeout(async () => {
                await this.ngOnInit();
            }, 1000);
            return;
        }
        console.log("done");

        this.userForm = new FormGroup({
            'username': new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(this.usernameMaxLength), Validators.pattern(/^[a-zA-Z0123456789.-]+$/), this.checkForDuplicateUsername()]),
            'email': new FormControl(null, [Validators.required, Validators.email, Validators.minLength(1), Validators.maxLength(this.emailMaxLength), Validators.pattern(/^[a-zA-Z0123456789.@]+$/), this.checkForDuplicateEmail()]),
            'firstName': new FormControl([], [Validators.minLength(1), Validators.maxLength(this.firstNameMaxLength), Validators.pattern(/^[a-zA-Z-]+$/)]),
            'lastName': new FormControl(null, [Validators.minLength(1), Validators.maxLength(this.lastNameMaxLength), Validators.pattern(/^[a-zA-Z-]+$/)]),
            'accessLevel': new FormControl([], Validators.required),
        }, { validators: [this.checkForChanges()] });

    }

    usersOverviewClick() {
        console.log("Bye");
        if (this.changesMade) {
             this.approveConfirmers.find(ac => ac.taskId == "userclose").showApproveDialog(null);
        }
        else {
            this.displayUserEditor(false);
        }
    }

    usersOverview() {
        console.log("Bye");
        this.errorMessage = '';
        this.displayUserEditor(false);
    }

    showLoading(showSpinner: boolean) {
        if (showSpinner) {
            console.log("showing spinner");
            this.loadingSpinner.showSpinner();
        }
        else {
            console.log("hiding spinner");
            this.loadingSpinner.hideSpinner();
        }

    }

    updateUserFormValues(user: UserDetails) {
        this.userForm.controls['username'].patchValue(user.username);
        this.userForm.controls['firstName'].patchValue(user.firstName);
        this.userForm.controls['lastName'].patchValue(user.lastName);
        this.userForm.controls['email'].patchValue(user.email);
        this.userForm.controls['accessLevel'].patchValue(user.accessLevel);
        this.changesMade = false;
    }

    createUser() {
        console.log("Create new user ");
        this.userUpdating = false;
        this.userCreating = true;
        this.errorMessage = '';
        this.userForm.reset();

        let newUser: UserDetails = new UserDetails();
        newUser.username = this.defaultUsername;
        newUser.email = this.defaultEmail;
        newUser.firstName = this.defaultFirstName;
        newUser.lastName = this.defaultLastName;
        newUser.accessLevel = this.defaultAccessLevel;

        this.originalUser = newUser;

        this.updateUserFormValues(newUser);
        
        this.changesMade = false;
        this.enableSave = false;
        this.displayUserEditor(true);
    }

    async editUser(id: string) {
        console.log("Edit user " + id);

        this.userCreating = false;
        this.userUpdating = true;
        var userToEdit = await this.userService.getUser(id);
        var userGroup = await this.userService.getUserGroup(userToEdit.id);
        if (userGroup.length != 0) {
            userToEdit.accessLevel = userGroup[0].name;
        }
        else {
            userToEdit.accessLevel = null;
        }

        if (userToEdit) {
            this.updateUserFormValues(userToEdit)
            this.originalUser = userToEdit;
            this.displayUserEditor(true);
        } 
    }

    displayUserEditor(showEditor: boolean) {
        this.errorMessage = '';
        this.showUsersOverview = !showEditor;
        this.showUserEditor = showEditor;
    }

    async taskApproved(taskid: string) {
        let taskSuccess: boolean = false;
        let showSuccess: boolean = true;

        if (taskid == "userdelete") {
            taskSuccess = await this.deleteUser(this.userToDelete);
        }
        else if (taskid == "usersave" || taskid == "userupdate") {
            taskSuccess = await this.saveUser();
        }
        else if (taskid == "userclose") {
            this.usersOverview();
            this.userForm.reset();
            showSuccess = false;
        }


        // After succeeded/failed message goes to user, methods taskResultAccepted or taskRetry will fire when the user selects one of those
        // ANy postprocessing to be done after the task is done, eg. closing a popup, should be done there
        if (showSuccess) {
            if (taskSuccess) {
                this.approveConfirmers.find(ac => ac.taskId == taskid).showTaskSucceeded();
            }
            else {
                this.approveConfirmers.find(ac => ac.taskId == taskid).showTaskFailed();
            }
        }
        else {
            this.approveConfirmers.find(ac => ac.taskId == taskid).hide();
        }
    }

    taskCancelled(taskid: string) {
    }

    taskResultAccepted(taskResult: TaskResult) {
    }

    taskRetry(taskResult: TaskResult) {
        // Just calls taskApproved to run the same logic as was done for the task originally
        // but could do something more complex if needed.
        this.taskApproved(taskResult.taskId);
    }

    deleteUserSetup(user: UserDetails, event) {

        // Do not allow the select to  pass on to the user box that contains the delete icon
        // otherwise the user editor will be displayed too
        event.stopPropagation();
        this.userToDelete = user;
        // After this, methods taskApproved or taskCancelled will fire when the user selects one of those
        this.approveConfirmers.find(ac => ac.taskId == "userdelete").showApproveDialog(undefined);
        

    }

    async deleteUser(user: UserDetails): Promise<boolean> {

        let result: ServiceResultUser;

        console.log("Deleting user " + user.username);

        this.showLoading(true);
        result = await this.userService.deleteUser(user.id);
        this.users = await this.userService.getAll();
        for (var i = 0; i < this.users.length; i++) {
            var userGroup = await this.userService.getUserGroup(this.users[i].id);
            if (userGroup.length != 0) {
                this.users[i].accessLevel = userGroup[0].name;
            }
        }
        this.showLoading(false);

        if (!(result)) {
            this.approveConfirmers.find(ac => ac.taskId == "userdelete").setErrorMessage(result.errorMessage);
        }

        return result.success;
    }


    compressText(name: string): string {
        let compressedName: string = name;
        while (compressedName.indexOf(" ") >= 0) {
            compressedName = compressedName.replace(" ", "");
        }
        return compressedName.trim();
    }

    checkForDuplicateEmail(): ValidatorFn {
        return (control: FormGroup): ValidationErrors | null => {
            if (!this.userForm || !control.value || this.userUpdating) {
                return null;
            }
            else if (this.users.map(x => x.email).find(x => x.toLowerCase() == control.value.toLowerCase())) {
                return { duplicateEmail: true };
            }
        }
    }

    checkForDuplicateUsername(): ValidatorFn {
        return (control: FormGroup): ValidationErrors | null => {
            if (!this.userForm || !control.value || this.userUpdating) {
                return null;
            }
            else if (this.users.map(x => x.username).find(x => x.toLowerCase() == control.value.toLowerCase())) {
                return { duplicateUsername: true };
            }
        }
    }

    checkForChanges(): ValidatorFn {
        return (control: FormGroup): ValidationErrors | null => {
            this.changesMade = false;
            if (!this.originalUser) {
                return null;
            }
            Object.keys(control.controls).forEach(key => {
                if (control.controls['username'] == control.controls[key]) {
                    if (control.controls[key].value != this.originalUser.username) {
                        this.changesMade = true;
                    }
                }
                else if (control.controls['firstName'] == control.controls[key]) {
                    if (control.controls[key].value != this.originalUser.firstName) {
                        this.changesMade = true;
                    }
                }
                else if (control.controls['lastName'] == control.controls[key]) {
                    if (control.controls[key].value != this.originalUser.lastName) {
                        this.changesMade = true;
                    }
                }
                else if (control.controls['email'] == control.controls[key]) {
                    if (control.controls[key].value != this.originalUser.email) {
                        this.changesMade = true;
                    }
                }
                else if (control.controls['accessLevel'] == control.controls[key]) {
                    if (control.controls[key].value != this.originalUser.accessLevel) {
                        this.changesMade = true;
                    }
                }
            });
            return null;
        }
    }

    saveUserSetup() {
        // After this, methods taskApproved or taskCancelled will fire when the user selects one of those
        let changes: Array<string> = [];

        if (this.originalUser.username != this.username) {
            changes.push("Username changed to: " + this.username);
        }

        if (this.originalUser.email != this.email) {
            changes.push("Email changed to: " + this.email);
        }

        if (this.originalUser.firstName != this.firstName) {
            changes.push("First name changed to: " + this.firstName);
        }

        if (this.originalUser.lastName != this.lastName) {
            changes.push("Last name changed to: " + this.lastName);
        }

        if (this.originalUser.accessLevel != this.accessLevel) {
            changes.push("Access Level changed to: " + this.accessLevel);
        }
        if (this.originalUser.id) {
            this.approveConfirmers.find(ac => ac.taskId == "userupdate").showApproveDialog(changes);
        }
        else {
            this.approveConfirmers.find(ac => ac.taskId == "usersave").showApproveDialog(changes);
        }
   
    }

    async saveUser(): Promise<boolean> {
        console.log("Saving user " + this.username);
        let result: ServiceResultUser;
        let userToSave: UserDetails;
        userToSave = {
            id: this.originalUser.id,
            username: this.username,
            firstName: this.firstName,
            lastName: this.lastName,
            email: this.email,
            accessLevel: this.accessLevel
        }

        console.log("userToSave " + JSON.stringify(userToSave));

        this.showLoading(true);
        if (this.originalUser.id == null) {
            result = await this.userService.createNewUser(userToSave);
        }
        else {
            result = await this.userService.editUser(userToSave);
            var oldGroupId = this.groups.filter(x => x.name == this.originalUser.accessLevel)[0].id;
            var newGroupId = this.groups.filter(x => x.name == userToSave.accessLevel)[0].id;
            result = await this.userService.updateUserGroup(userToSave.id, oldGroupId, newGroupId);
        }

        this.showLoading(false);
        console.log("saveUser(comp): result = " + JSON.stringify(result));

        if (!(result.success)) {
            this.approveConfirmers.find(ac => ac.taskId == "usersave").setErrorMessage(result.errorMessage);
        }
        else {
            this.showLoading(true);
            this.users = await this.userService.getAll();
            for (var i = 0; i < this.users.length; i++) {
                var userGroup = await this.userService.getUserGroup(this.users[i].id);
                if (userGroup.length != 0) {
                    this.users[i].accessLevel = userGroup[0].name;
                }
            }
            this.showLoading(false);
        }

        if (result.success && ((userToSave.id == undefined) || (userToSave.id == this.defaultId))) {
            var newUser = this.users.find(x => x.username == userToSave.username.toLowerCase());
            userToSave.id = newUser.id;
        }

        if (result.success) {
            this.originalUser = { ...userToSave };
            this.updateUserFormValues(userToSave);
            this.userForm.markAsPristine();
            this.changesMade = false;
        }

        return result.success;
    }
}
