import { PageMVC } from "./page-mvc";

export class PageSequenceMVC {
    // NB. Fields should be same as PageSequenceMVC
    sequenceId: string;
    creatorEmail: string;
    name: string;
    pages: PageMVC[];
    pageOrderChanged: boolean;
}
