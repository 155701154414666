import{ Injectable }from '@angular/core';
@Injectable({ providedIn: 'root' })
export class CookieServiceUtil
{
    //Get cookie function taking a cookie name as parameter and getting the value
    public GetCookie(cname)
    {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++)
        {
            let c = ca[i];
            while (c.charAt(0) == ' ')
            {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0)
            {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    //A function to create a cookie
    public AddCookie(cookieName:string, value: string)
    {
        document.cookie = cookieName + "=" + value + ";path=/";
    }

    public AddCookieWithExp(cookieName: string, value: string, minutes: number) {
        var currentDate = new Date();
        var futureDate = new Date(currentDate.getTime() + (minutes * 60000));
        document.cookie = cookieName + "=" + value + ";path=/; expires=" + futureDate.toUTCString();
    }

}

