import { Component, ViewChildren, ViewChild, QueryList } from '@angular/core';
import * as $ from 'jquery';
import { PageSequencingService } from '@app/_services/page-sequencing.service';
import { HttpClient } from '@angular/common/http';
import { AppConfigService, PublishService } from 'shared';
import { PageSequence } from '@app/_models/page-sequencing/angular/page-sequence';
import { Page } from '@app/_models/page-sequencing/angular/page';
import { ServiceResultPageSequence } from '@app/_models/page-sequencing/angular/service-result-page-sequence';
import { TaskResult } from 'shared';
import { ApproveConfirmComponent } from 'shared';
import { LoadingComponent } from 'shared';
import { CdkDragMove, CdkDrag, CdkDropList, CdkDropListGroup, moveItemInArray, CdkDragDrop, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop'
import { environment } from '@environments/environment';
import { isNullOrUndefined } from '@swimlane/ngx-datatable/release/utils';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IMultiSelectOption, IMultiSelectSettings } from 'angular-2-dropdown-multiselect';
import { Proximity } from '../../_models/page-sequencing/angular/proximity';
import { Flag } from '../../_models/page-sequencing/angular/flag';
import { associatedItem } from '../../_models/page-sequencing/angular/associatedItem';
import { deepStrictEqual } from 'assert';
import { Rule } from '../../_models/page-sequencing/KePages/rule';
import { repeat } from 'rxjs/operators';

@Component({
    selector: 'page-sequencing',
    templateUrl: './page-sequencing.component.html',
})

export class PageSequencingComponent {

    @ViewChildren(ApproveConfirmComponent) approveConfirmers: QueryList<ApproveConfirmComponent>;
    @ViewChild(LoadingComponent, { static: false }) loadingSpinner: LoadingComponent;
    @ViewChild(CdkDropListGroup, { static: false }) listGroup: CdkDropListGroup<CdkDropList>;
    @ViewChild(CdkDropList, { static: false }) placeholder: CdkDropList;

    private PageSequencingService: PageSequencingService;
    private appConfigService: AppConfigService;
    orderChangedCheckResult: boolean = false;
    pageActive: boolean = false;
    targetSystem: string = environment.targetSystem;
    pageSequences: PageSequence[];
    pages: Page[];
    origionalPages: Page[]
    newPage: Page;
    typeOfFlagsCondition = ["=", "<", ">", "!=", "<=", ">="];
    newPageObject: Page[];
    pageSequenceEditorModel: PageSequence;
    pageSequenceToDelete: PageSequence;
    showPageSequencesOverview: boolean = false;
    showPageSequenceEditor: boolean = true;
    errorMessage: string = '';
    enableSave: boolean;
    changesMade: boolean = false;
    orderChanged: boolean = false;
    intialCheckDone: boolean = false;
    typeOfMedia = [];
    associatedMessages = [];
    associatedMediaSets = [];
    associatedTargetPages = [];
    typeOfFlagsTypes = [];
    typeOfFlagsTexts = [];
    targetPageData;
    pageSequenceNameMaxLength: number = 50;
    keyPressTimeoutMs: number = 500;
    defaultPageDisplayDuration: number = 15;
    maxNumberOfPages = 0;
    flagValueRestrictions = []
    mandatorySequence = [];

    mediaRequestSuccess: boolean = false
    messageRequestSuccess: boolean = false
    targetPageRequestSuccess: boolean = false



    minMaxOfValue = [0, 1]
    selectedType: string = "";
    selectedMediaSet: string;
    selectedMessage: string;
    selectedTargetPage: string;

    nrSelectedMessage;
    nrSelectedTarget;
    nrSelectedMedia;

    pageSelected: number;
    flagDeleted: boolean;
    proximityDeleted: boolean;

    selectedPage: Page;
    originalSequenceName: string;
    pageSequenceNameValid: boolean = true;

    //Permissions
    canGetPageSequences: boolean;
    canChangePageSequence: boolean;
    canDeletePageSequence: boolean;
    canCreatePageSequence: boolean;

    //user actions
    userCreating: boolean;
    userUpdating: boolean;
    errorMessageOutput: string;
    pageSequenceNameTypingTimer = undefined;

    public target: CdkDropList;
    public targetIndex: number;
    public source: CdkDropList;
    public sourceIndex: number;
    public dragIndex: number;
    public activeContainer;


    repeatTimeModalForm: FormGroup;
    flagModalForm: FormGroup;
    proximityModalForm: FormGroup;
    closeResult: string;
    repeatTimeValue: number;
    isDestinationFlag: boolean = false;

    media: associatedItem[];
    messages: associatedItem[];

    publishEndTime: Date = null;

    constructor(private httpClient: HttpClient, private modalService: NgbModal, private publishService: PublishService) {
        this.appConfigService = new AppConfigService(this.httpClient);

        this.PageSequencingService = new PageSequencingService(this.httpClient, this.appConfigService);

        console.log("Constructing page PageSequence overview");
        this.userCreating = false;
        this.userUpdating = false;
    }

    async ngOnDestroy() {
        this.pageActive = false;
    }

    async ngOnInit() {
        this.pageActive = true;
        console.log("Initialising page PageSequence component");

        this.canChangePageSequence = this.appConfigService.userHasPermission('Permissions.CanChangePageSequence');
        this.canCreatePageSequence = this.appConfigService.userHasPermission('Permissions.CanCreatePageSequence');
        this.canDeletePageSequence = this.appConfigService.userHasPermission('Permissions.CanDeletePageSequence');
        this.canGetPageSequences = this.appConfigService.userHasPermission('Permissions.CanGetPageSequences');
        //selection check box parameters
        this.sectionSettings = {
            enableSearch: true,
            checkedStyle: 'checkboxes',
            buttonClasses: 'form-select page-sequence-edit-textbox form-control-lg',
            containerClasses: '',
            dynamicTitleMaxItems: 1,
            displayAllSelectedText: true,
            closeOnSelect: false,
            showCheckAll: true,
            showUncheckAll: true,
        };
        console.log("Config: keyPressTimeoutMs = " + this.keyPressTimeoutMs);
        if (this.canGetPageSequences) {
            this.showPageSequencesOverview = true;
            this.showPageSequenceEditor = false;
        }

        var config = await this.PageSequencingService.retrieveConfig();
        this.typeOfMedia = config.typeOfMedia;
        for (var targetPage of config.targetPages) {
            this.associatedTargetPages.push(targetPage.name)
        }
        console.log(JSON.stringify(this.associatedTargetPages) + " target pages")
        this.targetPageData = config.targetPages;
        this.typeOfFlagsTypes = config.flagTypes;
        this.pageSequenceNameMaxLength = config.sequenceMaxNameLength;
        this.keyPressTimeoutMs = config.keyPressTimeoutMs;
        this.defaultPageDisplayDuration = config.defaultPageDisplayDuration;
        this.flagValueRestrictions = config.flagRestrictions;
        this.mandatorySequence = config.mandatorySequence;
        console.log(JSON.stringify(this.mandatorySequence) + "mandatory seq")
        this.maxNumberOfPages = config.maxNumberOfMediaSets;
        this.Stations = config.stations;
        this.createPage("", "");
        this.keyPressTimeoutMs = environment.keyPressTimeoutMs;
        this.typeOfMedia = await this.typeOfMedia.filter(item => item != "Message")
        this.typeOfMedia = await this.typeOfMedia.filter(item => item != "Media")
        console.log(this.selectedType)
        try {
            var tempAssociatedMessages = await this.PageSequencingService.getMessages();
            this.messageRequestSuccess = true;
            if (tempAssociatedMessages != null) {
                this.associatedMessages = []
                this.messages = tempAssociatedMessages;
            }
            for (var item of tempAssociatedMessages) {
                this.associatedMessages.push(item.name)
            }
        } catch (e) {
            this.messageRequestSuccess = false;
        }
        if (this.associatedMessages.length == 0) {
            this.typeOfMedia = await this.typeOfMedia.filter(item => item != "Message")
        } else {
            this.typeOfMedia.push("Message");
        }
        try {
            var tempAssociatedMediaSets = await this.PageSequencingService.getMediaSets()
            this.mediaRequestSuccess = true;
            if (tempAssociatedMediaSets != null) {
                this.associatedMediaSets = []
                this.media = tempAssociatedMediaSets;
            }
            for (var item of tempAssociatedMediaSets) {
                this.associatedMediaSets.push(item.name)
            }
        } catch (e) {
            this.mediaRequestSuccess = false;
        }
        if (this.associatedMediaSets.length == 0) {
            console.log("removing Media from media types")
            this.typeOfMedia = await this.typeOfMedia.filter(item => item != "Media")
        } else {
            this.typeOfMedia.push("Media");
        }


        this.targetPageRequestSuccess = true;
        if (this.canGetPageSequences) {
            this.showPageSequencesOverview = true;
            this.showPageSequenceEditor = false;
            try {
                this.pageSequences = await this.PageSequencingService.getPageSequences();
            } catch (e) {
                if (e.error.detail == "No connection could be made because the target machine actively refused it.") {
                    this.errorMessageOutput = "No connection to " + environment.targetSystem + " could be established.";
                } else if (e.error.detail == "Error on the server") {
                    this.errorMessageOutput = "Error on the " + this.targetSystem + " server.";
                }
            }
        }
        if (this.pageSequences == null) {
            await this.attemptSequences();
        }

        //Check if a publish is happening
        if (environment.rulePluginEnabled == true || environment.rulePluginEnabled == "true") {
            await this.publishService.init();
            this.publishEndTime = await this.publishService.GetPublishEndTime();
            this.publishService.publishUpdateEvent.subscribe((newTime) => {
                this.publishEndTime = newTime;
            });
        }
    }

    async attemptSequences() {
        try {
            this.pageSequences = await this.PageSequencingService.getPageSequences();
        } catch (e) {
            if (e.error.detail == "No connection could be made because the target machine actively refused it.") {
                this.errorMessageOutput = "No connection to " + environment.targetSystem + " could be established.";
            } else if (e.error.detail == "Error on the server") {
                this.errorMessageOutput = "Error on the " + this.targetSystem + " server.";
            }
            setTimeout(async () => {
                if (!this.pageActive) {
                    return null;
                }
                await this.attemptSequences();
            }, 1000);
        }
    }

    //changes names greater than 18 e.g. verylongna...
    calculateNewName(name: string) {
        if (name.length > 18) {
            name = name.substring(0, 16) + "..."
        }
        return name;
    }

    isDefault() {
        if (this.originalSequenceName == "Default pageset") {
            return true;
        } else {
            return false;
        }
    }

    isDefaultSequence(pageSequenceName: string) {
        if (pageSequenceName == "Default pageset") {
            return true;
        } else {
            return false;
        }
    }   

    ngAfterViewChecked() {
        if (this.intialCheckDone) {
            return;
        }

        if (this.placeholder != undefined) {
            let phElement = this.placeholder.element.nativeElement;
            phElement.style.display = 'none';
            phElement.parentElement.removeChild(phElement);
            this.intialCheckDone = true;
        }
    }
    //selected type
    getSelectedType(type: string) {
        this.nrSelectedMessage = null;
        this.nrSelectedTarget = null;
        this.nrSelectedMedia = null;

        if (type != this.selectedType) {
        }
        this.selectedType = type;
        if (type == "Message") {
            if (!this.isMultipleMessages()) {
                this.createPage("", "Message");
            } else {
                this.createPage("Message Page", "Message");
            }
        } else {
            this.createPage("", type);
        }
        console.log(this.selectedType)
    }

    getMediaSetSelection(mediaSet: string) {
        this.selectedMediaSet = mediaSet;
        this.createPage(mediaSet, "Media");
    }

    getMessageSelection(message: string) {
        this.selectedMessage = message;
        this.createPage(message, "Message");
    }

    getTargetPageSelection(target: string) {
        this.selectedTargetPage = target;
        this.createPage(target, "Target Page");
    }

    getAssociatedTargetId(name) {
        for (var targetPage of this.targetPageData) {
            if (targetPage.name == name) {
                return targetPage.id
            }
        }
        return null;
    }

    getAssociatedMediaId(name) {
        for (var media of this.media) {
            if (media.name == name) {
                return media.id
            }
        }
        return null;
    }

    getAssociatedMessageId(name) {
        for (var message of this.messages) {
            if (message.name == name) {
                return message.id
            }
        }
        return null;
    }

    //creates a page object and creates a new flag for target pages
    createPage(name: string, mediaType: string) {
        console.log(this.pages)
        var id = ""
        var sequencePosition = 0;
        if (this.pages != undefined) {
            var deletedPages = 0;
            for (var page of this.pages) {
                if (page.isDeleted) {
                    deletedPages++;
                }
            }
            var id = this.pages.length.toString()
            sequencePosition = this.pages.length - deletedPages;
            console.log(sequencePosition)
        }
        var assocId = null;
        if (mediaType == "Message") {
            assocId = null; //message will be sent separately
        } else if (mediaType == "Media") {
            assocId = this.getAssociatedMediaId(name);
        } else if (mediaType == 'Target Page') {
            assocId = this.getAssociatedTargetId(name);
        }

        this.newPage = {
            pageDataId: id,
            name: name,
            associatedData: assocId,
            enabled: true,
            pageType: mediaType,
            displayDuration: this.defaultPageDisplayDuration,
            sequencePosition: sequencePosition,
            isNew: true,
            isDeleted: false,
            repeatTime: 0,
            flag: undefined,
            proximity: null,
            isEdited: false,
            isReadOnly: false,
            itemFound: false,
            itemWarning: false,
            isHovered: false,
        };
        if (mediaType == "Target Page") {
            for (var targetPage of this.targetPageData) {
                if ((targetPage.condition != "") && (name == targetPage.name)) {
                    //only set flag for a target page with flag data
                    const flag: Flag = new Flag();
                    flag.flagID = null;
                    flag.text = targetPage.text;
                    flag.condition = targetPage.condition;
                    flag.value = targetPage.value;
                    flag.isDeleted = false;
                    flag.isEdited = false;
                    flag.isNew = true;
                    this.newPage.flag = flag;
                }
            }
        }
        console.log(this.newPage)
    }
    //add the selected page to pages
    async addPageToSequence() {
        console.log(this.newPage.pageType)
        if (this.selectedType == "Media") {
            this.createPage(this.selectedMediaSet, "Media");
        } else if (this.selectedType == "Message") {
            this.createPage("Message Page", "Message");
        } else if (this.selectedType == "Target Page") {
            this.createPage(this.selectedTargetPage, "Target Page");
        }
        this.pages.push(this.newPage)
        this.pages = await this.checkPagesExist(this.pages)
        this.checkOrder()
    }

    //checks for multiple message
    isMultipleMessages() {
        for (let page = 0; page < this.pages.length; page++) {
            if (this.pages[page].pageType.toLowerCase() == "message" && !this.pages[page].isDeleted) {
                return false;
            }
        }
        return true;
    }
    //drag and drop functions
    dragMoved(event: CdkDragMove) {
        let point = event.pointerPosition;
        this.listGroup._items.forEach(dropList => {
            if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
                this.activeContainer = dropList;
                return;
            }
        });
    }

    dropListDropped() {
        if (!this.target)
            return;
        let phElement = this.placeholder.element.nativeElement;
        let parent = phElement.parentElement;
        phElement.style.display = 'none';
        parent.removeChild(phElement);
        parent.appendChild(phElement);
        parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);
        this.target = null;
        this.source = null;
        if (this.sourceIndex == 0 || this.targetIndex == 0) {
            this.approveConfirmers.find(ac => ac.taskId == "pagesequencedragdrop").showTaskSucceeded();
            this.pages = this.pages;
        } else {
            if (this.sourceIndex != this.targetIndex) {
                moveItemInArray(this.pages, this.sourceIndex, this.targetIndex);
                this.orderChanged = true;
            }
            console.log(this.pages)
            this.updateOrder();
            this.checkOrder();
        }
    }

    dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
        if (drop == this.placeholder)
            return true;
        if (drop != this.activeContainer)
            return false;
        let phElement = this.placeholder.element.nativeElement;
        let sourceElement = drag.dropContainer.element.nativeElement;
        let dropElement = drop.element.nativeElement;
        let dragIndex = __indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
        let dropIndex = __indexOf(dropElement.parentElement.children, dropElement);
        if (!this.source) {
            this.sourceIndex = dragIndex;
            this.source = drag.dropContainer;
            phElement.style.width = sourceElement.clientWidth + 'px';
            phElement.style.height = sourceElement.clientHeight + 'px';
            if (sourceElement.id != "cdk-drop-list-1") {
                sourceElement.parentElement.removeChild(sourceElement);
            }
        }
        this.targetIndex = dropIndex;
        this.target = drop;
        phElement.style.display = '';
        dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
            ? dropElement.nextSibling : dropElement));
        this.placeholder._dropListRef.enter(drag._dragRef, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
        return false;
    }


    dropListEnterPredicateFalse = (drag: CdkDrag, drop: CdkDropList) => {
        return false;
    }
    //updates the order
    updateOrder() {
        var position = 0;
        for (var page = 0; page < this.pages.length; page++) {
            if (!this.pages[page].isDeleted) {
                this.pages[page].sequencePosition = position;
                position++;
            }
        }
    }
    //bubble sorts the pages by sequence position
    checkOrder() {
        console.log(this.pages)
        for (let i = 0; i < this.pages.length; i++) {
            for (let j = 0; j < this.pages.length - 1; j++) {
                if (this.pages[j].sequencePosition > this.pages[j + 1].sequencePosition) {
                    let swap = this.pages[j];
                    this.pages[j] = this.pages[j + 1];
                    this.pages[j + 1] = swap;
                }
            }
        }
        console.log(this.pages)
    }
    //check for multiple messages
    checkMessageLimit() {
        if (this.selectedType == "Message") {
            for (let i = 0; i < this.pages.length; i++) {
                if (this.pages[i].pageType == "Message" || this.pages[i].name == "Message Page" && !this.pages[i].isDeleted) {
                    return true;
                }
            }
        }
        return false;
    }

    PagesLength() {
        var len = 0;
        if (this.pages == null) {
            return 0;
        }
        for (let i = 0; i < this.pages.length; i++) {
            if (!this.pages[i].isDeleted) {
                len++
            }
        }
        return len;
    }

    async pageSequencesOverviewClick() {
        var changes = this.checkChanges();
        if (changes.length == 0) {
            //if a page sequence has been added the page sequences needs refreshing to show it.
            try {
                this.pageSequences = await this.PageSequencingService.getPageSequences();
            } catch (e) {
                if (e.error.detail == "No connection could be made because the target machine actively refused it.") {
                    this.errorMessageOutput = "No connection to " + environment.targetSystem + " could be established.";
                } else if (e.error.detail == "Error on the server") {
                    this.errorMessageOutput = "Error on the " + this.targetSystem + " server.";
                }
            }
            this.displayPageSequenceEditor(false);
        } else {
            this.approveConfirmers.find(ac => ac.taskId == "pagesequenceclose").showApproveDialog(null);
        }
        console.log("Bye");
    }

    pageSequenceOverview() {
        console.log("Bye");
        this.errorMessage = '';
        this.pageSequenceNameValid = true;
        this.displayPageSequenceEditor(false);
    }

    showLoading(showSpinner: boolean) {
        if (showSpinner) {
            console.log("showing spinner");
            this.loadingSpinner.showSpinner();
        }
        else {
            console.log("hiding spinner");
            this.loadingSpinner.hideSpinner();
        }
    }

    async createPageSequence() {
        this.userUpdating = false;
        this.userCreating = true;
        this.errorMessage = '';
        this.pageSequenceNameValid = true;

        let newSequence: PageSequence = new PageSequence();
        newSequence.sequenceId = "";
        newSequence.name = "";
        this.pageSequenceEditorModel = newSequence;
        this.pages = [];
        this.originalSequenceName = this.pageSequenceEditorModel.name;
        this.changesMade = false;
        this.enableSave = false;
        this.displayPageSequenceEditor(true);
        for (let mandatoryItem = 0; mandatoryItem < this.mandatorySequence.length; mandatoryItem++) {
            let page: Page = new Page();
            page.pageDataId = mandatoryItem.toString()
            page.name = this.mandatorySequence[mandatoryItem].name
            page.displayDuration = this.mandatorySequence[mandatoryItem].displayDuration
            page.sequencePosition = this.mandatorySequence[mandatoryItem].sequencePosition
            page.enabled = this.mandatorySequence[mandatoryItem].enabled
            page.isNew = true
            page.isDeleted = false
            page.pageType = this.mandatorySequence[mandatoryItem].pageType
            page.repeatTime = this.mandatorySequence[mandatoryItem].repeatTime
            page.isReadOnly = this.mandatorySequence[mandatoryItem].isReadOnly,
                page.associatedData = this.mandatorySequence[mandatoryItem].associatedData
            this.pages.push(page);
        }
        this.pages = await this.checkPagesExist(this.pages);
        this.checkOrder()
        this.deepCopyArray();
        console.log(JSON.stringify(this.pages));
        // Wait a short period for the Media Set window to appear
        // before giving focus to the SequenceName text box
        setTimeout(() => {
            $('#SequenceName').focus();
        }, 500);
    }

    checkPageIsMandatory(page: Page) {
        if (page.pageType == "Default") {
            return true;
        }
        return false;
    }

    calculateBorderColor(warning: boolean, itemFound: boolean) {
        if (warning) {
            return 'yellow';
        }
        if (!itemFound) {
            return 'red';
        }
    }

    isInMedia(mediaItem: string) {
        if (this.media != null) {
            for (var media of this.media) {
                if (media.id == mediaItem) {
                    return media.name;
                }
            }
            return ""
        } else {
            return ""
        }
    }

    isInMessages(messageItem: string) {
        //messages are sent separately
        return "Message Page";
    }

    isInTargetPages(targetItem: string) {
        if (this.targetPageData != null) {
            for (var targetPage of this.targetPageData) {
                if (targetPage.id == targetItem) {
                    var result = targetPage.name;
                    return result;
                }
            }
            return ""
        } else {
            return ""
        }
    }

    async checkPagesExist(pages: Page[]) {
        for (var i = 0; i < pages.length; i++) {
            pages[i].itemFound = null;
            pages[i].itemWarning = null;
            if (pages[i].pageType == "Default") {
                pages[i].itemFound = true
                pages[i].itemWarning = false;
            } else {
                for (var types of this.typeOfMedia) {
                    if (pages[i].pageType == types) {
                        if (pages[i].pageType == "Media") {
                            var assocId = pages[i].associatedData
                            var name = this.isInMedia(assocId)
                            if (name == "") {
                                pages[i].itemFound = false;
                            } else {
                                pages[i].itemFound = true;
                            }
                        } else if (pages[i].pageType == "Message") {
                            var name = this.isInMessages(pages[i].associatedData)
                            if (name == "") {
                                pages[i].itemFound = false;
                            } else {
                                pages[i].itemFound = true;
                            }
                        } else if (pages[i].pageType == "Target Page") {
                            var TargetName = this.isInTargetPages(pages[i].associatedData)
                            if (TargetName == "") {
                                pages[i].itemFound = false;
                            } else {
                                pages[i].itemFound = true;
                            }
                            if (!this.targetPageRequestSuccess) {
                                pages[i].itemFound = false;
                                pages[i].itemWarning = true;
                            }
                        }
                        if (pages[i].itemFound) {
                            pages[i].itemWarning = false;
                        }
                    }
                }
                if (this.messageRequestSuccess && pages[i].pageType == "Message" && pages[i].itemFound == null && pages[i].itemWarning == null) {
                    pages[i].itemFound = false;
                    pages[i].itemWarning = false;
                } else if (this.mediaRequestSuccess && pages[i].pageType == "Media" && pages[i].itemFound == null && pages[i].itemWarning == null) {
                    pages[i].itemFound = false;
                    pages[i].itemWarning = false;
                } else if (this.mediaRequestSuccess && pages[i].pageType == "Target Page" && pages[i].itemFound == null && pages[i].itemWarning == null) {
                    pages[i].itemFound = false;
                    pages[i].itemWarning = false;
                }
                if (pages[i].itemFound == null && pages[i].itemWarning == null) {
                    pages[i].itemFound = false;
                    pages[i].itemWarning = true;
                }
            }
        }
        return pages;
    }

    async editPageSequence(sequenceId: string) {
        console.log("Edit page sequence " + sequenceId);
        this.userCreating = false;
        this.userUpdating = true;
        for (var i = 0; i < this.pageSequences.length; i++) {
            if (this.pageSequences[i].sequenceId == sequenceId) {
                this.pageSequenceEditorModel = new PageSequence();
                this.pageSequenceEditorModel.sequenceId = this.pageSequences[i].sequenceId;
                this.pageSequenceEditorModel.name = this.pageSequences[i].name;
                this.pageSequenceEditorModel.isEdited = false;
            }
        }

        this.showLoading(true);
        try {
            var pagesToCheck = await this.PageSequencingService.getPagesForPageSequence(sequenceId);
        } catch (error) {
            if (error == 204) {
                this.approveConfirmers.find(ac => ac.taskId == "pagesequencedeletedonopen").showTaskSucceeded();
                await this.attemptSequences();
                this.showLoading(false)
                return;
            } else {
                this.approveConfirmers.find(ac => ac.taskId == "pagesequencedeletedonopen").showTaskFailed();
                return;
            }
        }
        this.pages = await this.checkPagesExist(pagesToCheck);
        this.checkOrder()
        this.deepCopyArray();
        this.showLoading(false);
        this.originalSequenceName = this.pageSequenceEditorModel.name;
        this.pageSequenceNameValid = true;
        this.changesMade = false;
        if (this.originalSequenceName == "Default pageset") {
            this.enableSave = false;
        } else {
            this.enableSave = true;
        }
        this.displayPageSequenceEditor(true);
        console.log(this.pages);
    }
    //ensure the new page doesnt exceed max number of pages
    checkPageLengths() {
        var newestPage = 0;
        for (var page of this.pages) {
            if (!page.isDeleted) {
                newestPage = page.sequencePosition;
            }
        }
        if (newestPage != this.maxNumberOfPages - 1) {
            return true;
        }
        return false;
    }
    //deep copy of pages to hold origional array
    deepCopyArray() {
        this.origionalPages = [];
        const copy = JSON.parse(JSON.stringify(this.pages));
        this.origionalPages = copy;
    }

    displayPageSequenceEditor(showEditor: boolean) {
        console.log(JSON.stringify(this.pageSequences));
        this.errorMessage = '';
        this.showPageSequencesOverview = !showEditor;
        this.showPageSequenceEditor = showEditor;
    }

    getPageTypeIconSelector(selector) {
        var pageType = selector;
        var pageIconName = "";
        if ((pageType == "Media")) {
            pageIconName = "fa-image";
        }
        else if (pageType == "Message") {
            pageIconName = "fa-envelope";
        }
        else if (pageType == "Target Page") {
            pageIconName = "fa-file";
        } else if (pageType == "Default") {
            pageIconName = "fa-globe";
        }
        return pageIconName;
    }

    getPageTypeIcon(page: Page) {
        var pageType = page.pageType;
        var pageIconName = "";
        if ((pageType == "Media")) {
            pageIconName = "fa-image";
        }
        else if (pageType == "Message") {
            pageIconName = "fa-envelope";
        }
        else if (pageType == "Target Page") {
            pageIconName = "fa-file";
        } else if (pageType == "Default") {
            pageIconName = "fa-globe";
        }
        return pageIconName;
    }
    deletePageSequenceSetup(sequence: PageSequence, event) {
        // Do not allow the select to  pass on to the sequence set box that contains the delete icon
        // otherwise the Sequence editor will be displayed too
        event.stopPropagation();
        this.pageSequenceToDelete = sequence;
        // After this, methods taskApproved or taskCancelled will fire when the user selects one of those
        this.approveConfirmers.find(ac => ac.taskId == "pagesequencedelete").showApproveDialog(undefined);
    }
    deletePage(pageId: string) {
        console.log("Deleting " + pageId + " for page sequence " + this.pageSequenceEditorModel.sequenceId);
        // We don't remove the file from the sequence on the servers yet, instead we want to remember it and do it later on media set save
        // As a result, this has no approve-confirm.  If later we decide to have it happen 'outside' the main page sequence save
        // (that otherwise includes new pages, rules, etc.) then it can do that.
        // We don't *remove* the page from the list for the sequence here, just mark it deleted so that we can know not to display it
        // and - if we choose - display it marked deleted.  This also allows us to know how many pages (and which ones)
        // have been 'deleted' later when we save the page sequence.
        for (var i = 0; i < this.pages.length; i++) {
            if (this.pages[i].pageDataId == pageId) {
                if (this.pages[i].isNew) {
                    this.pages.splice(i, 1)
                } else {
                    this.pages[i].isDeleted = true;
                    this.pages[i].sequencePosition = 1000;
                }
            }
        }
        this.updateOrder();
        this.changesMade = true;
        this.checkOrder()
    }
    async taskApproved(taskId: string) {
        let taskSuccess: boolean = false;
        let showSuccess: boolean = true;

        if (taskId == "pagesequencedelete") {
            taskSuccess = await this.deleteSequence(this.pageSequenceToDelete.sequenceId);
        }
        else if (taskId == "pagesequencesave") {
            console.log(this.pageSequenceEditorModel)
            taskSuccess = await this.saveSequence(this.pageSequenceEditorModel.sequenceId);
            if (taskSuccess) {
                this.showLoading(true);
                this.pageSequenceEditorModel.sequenceId = this.pageSequenceEditorModel.sequenceId;
                await this.editPageSequence(this.pageSequenceEditorModel.sequenceId);
                this.showLoading(false);
            }
        }
        else if (taskId == "pagesequenceclose") {
            this.pageSequenceOverview();
            showSuccess = false;
        } else if (taskId == "pagesequencesavesetup") {
            this.savePageSequenceSetup(this.pageSequenceEditorModel.sequenceId);
        }

        // After succeeded/failed message goes to user, methods taskResultAccepted or taskRetry will fire when the user selects one of those
        // ANy postprocessing to be done after the task is done, eg. closing a popup, should be done there
        if (taskId != "pagesequencesavesetup") {
            if (showSuccess) {
                if (taskSuccess) {
                    this.approveConfirmers.find(ac => ac.taskId == taskId).showTaskSucceeded();
                }
                else {
                    this.approveConfirmers.find(ac => ac.taskId == taskId).showTaskFailed();
                }
            }
            else {
                this.approveConfirmers.find(ac => ac.taskId == taskId).hide();
            }
        }

    }
    taskCancelled(taskId: string) {
    }
    taskResultAccepted(taskResult: TaskResult) {
    }
    taskRetry(taskResult: TaskResult) {
        // Just calls taskApproved to run the same logic as was done for the task originally
        // but could do something more complex if needed.
        this.taskApproved(taskResult.taskId);
    }
    deleteSequenceSetup(PageSequence: PageSequence, event) {

        // Do not allow the select to  pass on to the media set box that contains the delete icon
        // otherwise the SequenceEditor will be displayed too
        event.stopPropagation();
        this.pageSequenceToDelete = PageSequence;
        // After this, methods taskApproved or taskCancelled will fire when the user selects one of those
        this.approveConfirmers.find(ac => ac.taskId == "pagesequencedelete").showApproveDialog(undefined);
    }
    async deleteSequence(sequenceId: string): Promise<boolean> {
        console.log("Deleting sequence" + sequenceId);

        this.showLoading(true);
        let result: ServiceResultPageSequence = await this.PageSequencingService.deletePageSequence(this.pageSequenceToDelete.sequenceId);
        this.showLoading(false);

        if (!(result.success)) {
            if (result.errorMessage == "423") {
                //Failed to delete page sequence: the page sequence is assigned to a rule. 423
                this.showLoading(true);
                let rules: Rule[] = await this.PageSequencingService.getRules();
                this.showLoading(false);
                var associatedRules = rules.filter(x => x.pageSequence.pageSequenceId == sequenceId).map(x => x.description);
                this.approveConfirmers.find(ac => ac.taskId == "pagesequencedelete").setErrorMessage(`This page sequence is assigned to these rules (${associatedRules.join(', ')}).`);
            } else {
                this.approveConfirmers.find(ac => ac.taskId == "pagesequencedelete").setErrorMessage(result.errorMessage);
            }
        }
        else {
            let newSequences: PageSequence[] = [];
            for (var i = 0; i < this.pageSequences.length; i++) {
                if (this.pageSequences[i].sequenceId != sequenceId) {
                    newSequences.push(this.pageSequences[i]);
                }
                else {
                    this.pageSequences[i].isDeleted = true;
                }
            }
            this.pageSequences = newSequences;
        }

        return result.success;
    }
    pageSequenceNameChanged() {
        this.pageSequenceEditorModel.name = <string>($("#pageSequenceName").val());
        if (this.pageSequenceEditorModel.name.trim().length < 1) {
            // Empty name - Key input was a backspace or delete
            this.pageSequenceNameValid = false;
        }
        else if (!(/^[a-zA-Z0123456789 ]+$/.test(this.pageSequenceEditorModel.name))) {
            // Invalid characters
            this.pageSequenceNameValid = false;
        }
        else if (this.pageSequenceEditorModel.name.trim().length > this.pageSequenceNameMaxLength) {
            // Too long
            this.pageSequenceNameValid = false;
        }
        else if (this.pageSequenceEditorModel.name.trim() == "") {
            // Not changed from default name
            this.pageSequenceNameValid = false;
        }
        else {
            this.pageSequenceNameValid = true;
            this.changesMade = true;
            for (var i = 0; i < this.pageSequences.length; i++) {
                if ((this.pageSequences[i].sequenceId != this.pageSequenceEditorModel.sequenceId)
                    && (this.compressSequenceName(this.pageSequences[i].name) == this.compressSequenceName(this.pageSequenceEditorModel.name))) {
                    // Name the same as an existing (different) page sequence
                    this.pageSequenceNameValid = false;
                }
            }
        }
        this.enableSave = this.saveIsValid();
    }
    compressSequenceName(name: string): string {
        let compressedName: string = name;
        while (compressedName.indexOf(" ") >= 0) {
            compressedName = compressedName.replace(" ", "");
        }
        return compressedName.trim().toLowerCase();
    }
    saveIsValid(): boolean {
        let saveValid: boolean = true;
        if ((!this.pageSequenceNameValid) || (!this.changesMade)) {
            saveValid = false;
        }
        if (this.originalSequenceName == "Default pageset") {
            return false;
        }
        return saveValid;
    }

    async isSaveConflictOrDeleteConflict(sequenceId: string) {
        var databasePages = null;
        var sequenceDeleted = false;
        var saveConflict = false;
        var errorFound = false;
        if (sequenceId != null && sequenceId.length == 36) {
            try {
                databasePages = await this.PageSequencingService.getPagesForPageSequence(this.pageSequenceEditorModel.sequenceId);
            } catch (error) {
                console.log(error);
                if (error == 204) {
                    sequenceDeleted = true;
                    this.showLoading(false);
                    return [saveConflict, sequenceDeleted, errorFound];
                } else {
                    this.approveConfirmers.find(ac => ac.taskId == "pagesequencesave").setErrorMessage(error.status);
                    this.showLoading(false);
                    errorFound = true;
                    return [saveConflict, sequenceDeleted, errorFound];
                }
            }
            try {
                var comparePages = [];
                const copy = JSON.parse(JSON.stringify(this.origionalPages));
                comparePages = copy;
                for (let i = 0; i < comparePages.length; i++) {
                    comparePages[i].itemFound = null;
                    comparePages[i].itemWarning = null;
                }
                console.log(databasePages);
                console.log(comparePages);
                deepStrictEqual(databasePages, comparePages);
            }
            catch (error) {
                console.log(error);
                saveConflict = true;
            }
            return [saveConflict, sequenceDeleted, errorFound];
        }
    }

    async savePageSequenceSetup(sequenceId: string) {
        console.log(this.pages)
        var changes = this.checkChanges();
        if (changes.length == 1 && this.orderChangedCheckResult) {
            this.pageSequenceEditorModel.pageOrderChanged = true;
        } else {
            this.pageSequenceEditorModel.pageOrderChanged = false;
        }
        console.log(sequenceId);
        this.showLoading(true);
        try {
            var newPageSequences = await this.PageSequencingService.getPageSequences();
            for (var sequence of newPageSequences) {
                if (sequence.sequenceId != this.pageSequenceEditorModel.sequenceId && sequence.name == this.pageSequenceEditorModel.name) {
                    this.approveConfirmers.find(ac => ac.taskId == "pagesequencesavesetup").setErrorMessage("A page sequence already exists with the name " + this.pageSequenceEditorModel.name);
                    this.pageSequences = newPageSequences;
                    this.approveConfirmers.find(ac => ac.taskId == "pagesequencesave").hide();
                    this.approveConfirmers.find(ac => ac.taskId == "pagesequencesavesetup").showTaskFailed();
                    this.pageSequenceNameValid = false;
                    this.enableSave = false;
                    return;
                }
            }
        } catch (error) {
            this.approveConfirmers.find(ac => ac.taskId == "pagesequencesavesetup").setErrorMessage(error);
            this.approveConfirmers.find(ac => ac.taskId == "pagesequencesavesetup").showTaskFailed();
            return;
        }
        if (sequenceId != null && sequenceId.length != 36) {
            this.approveConfirmers.find(ac => ac.taskId == "pagesequencesave").showApproveDialog(changes);
            return;
        }
        console.log(this.pages)
        var isConflictOrDeleteResult = await this.isSaveConflictOrDeleteConflict(this.pageSequenceEditorModel.sequenceId)
        this.showLoading(false);
        var taskSuccess = true;
        var saveConflict = false;
        var sequenceDeleted = false;
        if (isConflictOrDeleteResult[2]) {
            taskSuccess = false;
        } else if (isConflictOrDeleteResult[1]) {
            sequenceDeleted = true;
        } else if (isConflictOrDeleteResult[0]) {
            saveConflict = true;
        }
        if (!taskSuccess) {
            this.approveConfirmers.find(ac => ac.taskId == "pagesequencesavesetup").showTaskFailed();
        } else if (saveConflict) {
            this.approveConfirmers.find(ac => ac.taskId == "pagesequenceconflict").showTaskSucceeded();
        } else if (sequenceDeleted) {
            this.approveConfirmers.find(ac => ac.taskId == "pagesequencedeleted").showTaskSucceeded();
        }
        else {
            this.approveConfirmers.find(ac => ac.taskId == "pagesequencesave").showApproveDialog(changes);
        }
    }

    checkChanges() {
        console.log(this.pages);
        this.checkOrder();
        console.log(this.pages);
        let changes: Array<string> = [];
        let pagesAdded: number = 0;
        let pagesRemoved: number = 0;
        var seqPosition = 0;
        //ensures the sequence positions are valid
        this.pages.map(item => {
            if (!item.isDeleted) {
                item.sequencePosition = seqPosition;
                seqPosition++;
            }
        });
        //calculating the number of pages removed/ added
        for (var i = 0; i < this.pages.length; i++) {
            if ((this.pages[i].isNew) && (this.pages[i].isDeleted)) {
                // ignore, new page that was then deleted
            }
            else if (this.pages[i].isNew) {
                pagesAdded = pagesAdded + 1;
            }
            else if (this.pages[i].isDeleted) {
                pagesRemoved = pagesRemoved + 1;
            }
        }
        //repeat time, enabled and display duration changes
        let pagesEnabled: number = 0;
        let pagedDisabled: number = 0;
        let displayDurationChanged: number = 0;
        var id = 0
        let repeatTimeAdded: number = 0;
        let repeatTimeUpdated: number = 0;
        let repeatTimeDeleted: number = 0;
        var PageId = 0;
        //for each page check if the display duration, enabled or repeat time has been changed
        this.pages.map(item => {
            var originalPage = this.origionalPages.find(page => page.pageDataId == item.pageDataId);
            if (originalPage) {
                //display duration
                if (originalPage.displayDuration != item.displayDuration) {
                    displayDurationChanged++
                    this.pages[PageId].isEdited = true;
                }
                //enabled
                if (originalPage.enabled != item.enabled && originalPage.enabled) {
                    pagedDisabled++
                    this.pages[PageId].isEdited = true;
                }
                else if (originalPage.enabled != item.enabled && !originalPage.enabled) {
                    pagesEnabled++
                    this.pages[PageId].isEdited = true;
                }
                //repeat time
                if (originalPage.repeatTime == 0 && item.repeatTime != 0) {
                    repeatTimeAdded++
                    this.pages[PageId].isEdited = true;
                }
                else if (originalPage.repeatTime != 0 && item.repeatTime == 0) {
                    repeatTimeDeleted++
                    this.pages[PageId].isEdited = true;
                }
                else if (item.repeatTime != originalPage.repeatTime) {
                    if (item.repeatTime != 0) {
                        repeatTimeUpdated++
                        this.pages[PageId].isEdited = true;
                    }
                }
            }
            else {
                //display duration
                if (item.displayDuration != this.defaultPageDisplayDuration)
                {
                    displayDurationChanged++
                    this.pages[PageId].isEdited = true;
                }
                //enabled
                if (!item.enabled)
                {
                    pagedDisabled++
                    this.pages[PageId].isEdited = true;
                }
                //repeat time
                if (item.repeatTime != 0) {
                    repeatTimeAdded++
                    this.pages[PageId].isEdited = true;
                }
            }
            
            PageId++;
        });
        //calculating the number of page flags added, removed or updated
        let flagsAdded: number = 0;
        let flagsRemoved: number = 0;
        let flagsUpdated: number = 0;
        console.log(this.pages)
        for (var i = 0; i < this.pages.length; i++) {
            if ((this.pages[i].flag != undefined || this.pages[i].flag != null) && !this.pages[i].isDeleted) {
                if (this.pages[i].flag.isDeleted) {
                    flagsRemoved++;
                    this.pages[i].isEdited = true;
                } else if (this.pages[i].flag.isEdited) {
                    flagsUpdated++
                    this.pages[i].isEdited = true;
                } else if (this.pages[i].flag.isNew) {
                    flagsAdded++;
                    this.pages[i].isEdited = true;
                }
            }
        }
        //calculating the number of proximities added, removed or updated
        let proximityAdded: number = 0;
        let proximityRemoved: number = 0;
        let proximityUpdated: number = 0;
        for (var i = 0; i < this.pages.length; i++) {
            if (this.pages[i].proximity != undefined || this.pages[i].proximity != null) {
                if (this.pages[i].proximity.isDeleted) {
                    proximityRemoved++;
                    this.pages[i].isEdited = true;
                } else if (this.pages[i].proximity.isEdited) {
                    proximityUpdated++
                    this.pages[i].isEdited = true;
                } else if (this.pages[i].proximity.isNew) {
                    proximityAdded++;
                    this.pages[i].isEdited = true;
                }
            }
        }
        //checking if the order has changed
        if (this.orderChanged) {
            var order: string[]
            //compare the orders 
            order = this.pages.map(item => {
                if (!item.isDeleted) {
                    return item.name;
                } else {
                    return null;
                }
            });
            this.orderChangedCheckResult = false;
            id = 0
            console.log(this.origionalPages)
            if (this.origionalPages == undefined || this.pages.length != this.origionalPages.length) {
                this.orderChangedCheckResult = true;
            } else if (this.origionalPages != undefined) {
                this.pages.map(item => {
                    if (this.origionalPages[id] != undefined) {
                        if (item.isDeleted == false) {
                            if (item.pageDataId != this.origionalPages[id].pageDataId) {
                                this.orderChangedCheckResult = true;
                            }
                        } else {
                            this.orderChangedCheckResult = true;
                        }
                    } else {
                        this.orderChangedCheckResult = true;
                    }
                    id++;
                });
            }
            if (this.orderChangedCheckResult) {
                for (var page = 0; page < this.pages.length; page++) {
                    this.pages[page].isEdited = true;
                }
                for (var item = 0; item < order.length; item++) {
                    if (order[item] == null) {
                        order.splice(item, 1);
                    }
                }
                changes.push("Order of items: " + order.join(', '));
            }
        }
        //These functions create the array of changes to display to the user
        if (pagesAdded > 0) {
            changes.push(pagesAdded + " page(s) added");
        }
        if (pagesRemoved > 0) {
            changes.push(pagesRemoved + " page(s) removed");
        }
        if (repeatTimeAdded > 0) {
            changes.push(repeatTimeAdded + " repeat time(s) added ");
        }
        if (repeatTimeDeleted > 0) {
            changes.push(repeatTimeDeleted + " repeat time(s) removed");
        }
        if (repeatTimeUpdated > 0) {
            changes.push(repeatTimeUpdated + " repeat time(s) updated");
        }
        if (proximityAdded > 0) {
            changes.push(proximityAdded + " page proximity(s) added");
        }
        if (proximityRemoved > 0) {
            changes.push(proximityRemoved + " page proximity(s) removed");
        }
        if (proximityUpdated > 0) {
            changes.push(proximityUpdated + " page proximity(s) updated");
        }
        //Don't report on flags, as suppressed.
        //if (flagsAdded > 0) {
        //    changes.push(flagsAdded + " page flags added");
        //}
        //if (flagsRemoved > 0) {
        //    changes.push(flagsRemoved + " page flags removed");
        //}
        //if (flagsUpdated > 0) {
        //    changes.push(flagsUpdated + " page flags updated");
        //}
        if (pagesEnabled > 0) {
            changes.push(pagesEnabled + " page(s) switched to enabled");
        }
        if (pagedDisabled > 0) {
            changes.push(pagedDisabled + " page(s) switched to disabled");
        }
        if (displayDurationChanged > 0) {
            changes.push(displayDurationChanged + " page duration(s) updated");
        }
        if (this.originalSequenceName != this.pageSequenceEditorModel.name) {
            this.pageSequenceEditorModel.isEdited = true;
            changes.push("Page sequence name modified to: " + this.pageSequenceEditorModel.name);
        }
        return changes;
    }

    async findMediaID(name) {
        for (var media of this.media) {
            if (media.name == name) {
                return media.id;
            }
        }
        return null;
    }

    async findMessageID(name) {
        for (var message of this.messages) {
            if (message.name == name) {
                return message.id;
            }
        }
        return null;
    }

    async findTargetPageID(name) {
        for (var targetPage of this.targetPageData) {
            if (targetPage.name == name) {
                return targetPage.id;
            }
        }
        return null;
    }

    async saveSequence(sequenceId: string): Promise<boolean> {
        this.checkOrder();
        console.log(JSON.stringify(this.pages));
        console.log("Saving page sequence " + sequenceId);
        for (var i = 0; i < this.pages.length; i++) {
            this.pages[i].sequencePosition = i;
            var id = null;
            if (this.pages[i].pageType.toLowerCase() == "media" && this.pages[i].associatedData == null) {
                id = await this.findMediaID(this.pages[i].name);
                this.pages[i].associatedData = id;
            } else if (this.pages[i].pageType.toLowerCase() == "message" && this.pages[i].associatedData == null) {
                id = await this.findMessageID(this.pages[i].name)
                this.pages[i].associatedData = id;
            } else if (this.pages[i].pageType.toLowerCase() == "Target Page" && this.pages[i].associatedData == null) {
                id = await this.findTargetPageID(this.pages[i].name)
                this.pages[i].associatedData = id;
            }
            //Convert repeatTime into seconds
            this.pages[i].repeatTime = Math.trunc(this.pages[i].repeatTime) * 60;
        }
        console.log(JSON.stringify(this.pageSequenceEditorModel));
        this.showLoading(true);
        console.log(this.pages)
        let result: ServiceResultPageSequence = await this.PageSequencingService.savePageSequence(this.pageSequenceEditorModel.sequenceId, this.pageSequenceEditorModel.name, this.pageSequenceEditorModel.isEdited, "test@test.com", this.pages, this.pageSequenceEditorModel.pageOrderChanged);
        this.showLoading(false);

        console.log("saveSequence(comp): result = " + JSON.stringify(result));

        if (!(result.success)) {
            this.approveConfirmers.find(ac => ac.taskId == "pagesequencesave").setErrorMessage(result.errorMessage);
        } else if (result.success && result.id != null && result.id.length == 36) {
            this.showLoading(true);
            this.pageSequenceEditorModel.sequenceId = result.id;
            await this.editPageSequence(this.pageSequenceEditorModel.sequenceId);
            this.showLoading(false);
        } else if (result.id == null || result.id.length != 36) {
            console.log("Invalid Id returned " + result.id);
            if (result.errorMessage == "") {
                result.errorMessage = "Invalid Id returned the page sequence may have not been saved"
            }
            this.approveConfirmers.find(ac => ac.taskId == "pagesequencesave").setErrorMessage(result.errorMessage);
        }
        await this.checkOrder();
        return result.success;
    }
    keyPressHandler() {
        // Validation of the SequenceName is done when no key press event for
        // a configurable timeout period
        if (this.pageSequenceNameTypingTimer != undefined) {
            clearTimeout(this.pageSequenceNameTypingTimer);       // cancel old timer if they're still typing
        }
        this.pageSequenceNameTypingTimer = setTimeout(() => {     // set a new timer and remember a handle to it
            this.pageSequenceNameTypingTimer = undefined;
            this.pageSequenceNameChanged();
        }, this.keyPressTimeoutMs);                       // <-- time in ms to get from config
    }

    calcPagesPageId(pageId) {
        var pagesPageId = 0;
        for (let page = 0; page < this.pages.length; page++) {
            if (this.pages[page].pageDataId == pageId) {
                pagesPageId = page;
            }
        }
        return pagesPageId;
    }
    openRepeatTimeModel(content: any, pageId: any) {
        var pagesPageId = this.calcPagesPageId(pageId);
        this.repeatTimeValue = this.pages[pagesPageId].repeatTime;
        var oldRepeatTime = this.pages[pagesPageId].repeatTime;
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
            if (result == "Confirm Changes") {
                var repeatTime = this.repeatTimeModalForm.get('repeatTime').value;
                if (oldRepeatTime != repeatTime) {
                    console.log(JSON.stringify(this.origionalPages))
                    this.pages[pagesPageId].repeatTime = repeatTime;
                    console.log(JSON.stringify(this.origionalPages))
                    this.pages[pagesPageId].isEdited = true;
                }
            }
        }, (reason) => {
            console.log(reason)
        });
        this.repeatTimeModalForm = new FormGroup({
            'repeatTime': new FormControl(this.pages[pagesPageId].repeatTime, Validators.required),
        }, { validators: [] });
    }

    openProximityModel(content: any, pageId: any) {
        var pagesPageId = this.calcPagesPageId(pageId);
        this.pageSelected = pagesPageId
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            var stations = this.proximityModalForm.get("selectedStation");
            console.log(stations.value)
            this.closeResult = `Closed with: ${result}`;
            if (result == "Confirm Changes") {
                if (this.proximityDeleted) {
                    if (this.pages[pagesPageId].proximity.isNew) {
                        console.log("proximity null")
                        this.pages[pagesPageId].proximity = undefined
                    } else {
                        this.pages[pagesPageId].proximity.isDeleted = true;
                        this.pages[pagesPageId].proximity.isEdited = false;
                    }
                }
                console.log(this.pages);
                this.proximityDeleted = false;
                const proximity: Proximity = new Proximity();
                if (this.proximityModalForm.errors.validSelection) {
                    if (this.pages[pagesPageId].proximity === undefined || this.pages[pagesPageId].proximity == null || this.pages[pagesPageId].proximity.time == 0) {
                        proximity.isNew = true;
                    } else if (!proximity.isNew) {
                        proximity.isEdited = true;
                        proximity.isDeleted = false;
                        proximity.isNew = false;
                    }
                    proximity.proximityDestinationFlag = this.proximityModalForm.get('isDestination').value;
                    proximity.time = this.proximityModalForm.get('time').value;
                    var stationString = []
                    if (!proximity.proximityDestinationFlag) {
                        var selectedStations = stations.value;
                        console.log(selectedStations)
                        for (var station = 0; station < selectedStations.length; station++) {
                            stationString.push(this.Stations[selectedStations[station]].name);
                        }
                        proximity.stations = stationString;
                    }
                    if (proximity.time != 0) {
                        if (this.pages[pagesPageId].proximity != null) {
                            proximity.proximityId = this.pages[pagesPageId].proximity.proximityId
                        } else {
                            proximity.proximityId = null;
                        }
                        this.pages[pagesPageId].proximity = proximity;
                    }
                }
                this.proximityDeleted = false;
            }
            console.log(this.pages[pagesPageId].proximity)
        }, (reason) => {
        });
        var isDestination = false;
        var selectedStations = [];
        var time = 0;
        if (this.pages[pagesPageId].proximity != null && !this.pages[pagesPageId].proximity.isDeleted) {
            isDestination = this.pages[pagesPageId].proximity.proximityDestinationFlag;
            time = this.pages[pagesPageId].proximity.time
            for (var station in this.pages[pagesPageId].proximity.stations) {
                for (let stationItem = 0; stationItem < this.Stations.length; stationItem++) {
                    if (this.Stations[stationItem].name == this.pages[pagesPageId].proximity.stations[station]) {
                        selectedStations.push(stationItem);
                    }
                }
            }
        }
        this.proximityModalForm = new FormGroup({
            'isDestination': new FormControl(isDestination, Validators.required),
            'selectedStation': new FormControl(selectedStations, Validators.required),
            'time': new FormControl(time, Validators.required),
        }, { validators: [this.checkProximity()] });
        this.isDestinationFlag = isDestination;
    }

    deleteProximity() {
        this.proximityDeleted = true;
        this.isDestinationFlag = false;
        this.proximityModalForm.controls['isDestination'].setValue(false, { onlySelf: true });
        this.proximityModalForm.controls['selectedStation'].setValue([], { onlySelf: true });
        this.proximityModalForm.controls['time'].setValue(0, { onlySelf: true });

    }

    openFlagModel(content: any, pageId: any) {
        this.flagDeleted = false
        var pagesPageId = this.calcPagesPageId(pageId);
        this.pageSelected = pagesPageId;
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
            if (result == "Confirm Changes") {
                if (this.flagDeleted) {
                    console.log("deleting flag")
                    console.log(this.pages[pagesPageId].flag)
                    if (this.pages[pagesPageId].flag.isNew) {
                        this.pages[pagesPageId].flag = undefined
                    } else {
                        this.pages[pagesPageId].flag.isDeleted = true;
                        this.pages[pagesPageId].flag.isEdited = false;
                    }
                    console.log(this.pages[pagesPageId].flag)
                }
                this.flagDeleted = false
                console.log(this.flagModalForm.get("FlagType").value)
                if (this.flagModalForm.get("FlagType").value != undefined && this.flagModalForm.get("FlagType").value.length != 0) {
                    var text = this.flagModalForm.get("FlagText").value;
                    var condition = this.flagModalForm.get("FlagCondition").value;
                    var value = this.flagModalForm.get("value").value;
                    const flag: Flag = new Flag();
                    flag.flagID = null;
                    flag.text = text;
                    flag.condition = condition;
                    flag.value = value;
                    flag.isDeleted = false;
                    flag.isEdited = false;
                    flag.isNew = false;
                    console.log(this.pages[pagesPageId])
                    if (this.pages[pagesPageId].flag == undefined) {
                        flag.isNew = true;
                    } else {
                        flag.flagID = this.pages[pagesPageId].flag.flagID;
                        flag.isEdited = true;
                    }
                    this.pages[pagesPageId].flag = flag;
                }
                console.log(this.pages[pagesPageId].flag)
            } else {
                this.flagDeleted = false;
            }
        }, (reason) => {
        });
        this.flagModalForm = new FormGroup({
            'FlagType': new FormControl([], Validators.required),
            'FlagText': new FormControl([], Validators.required),
            'FlagCondition': new FormControl([], Validators.required),
            'value': new FormControl(0, Validators.required),
        }, { validators: [this.checkFlags()] });
        this.flagModalForm.controls['FlagType'].valueChanges.subscribe(() => {
            this.flagTypeChanged(this.flagModalForm.controls['FlagType'].value);
        })
        if (this.pages[pagesPageId].flag != undefined && !this.pages[pagesPageId].flag.isDeleted) {
            this.flagModalForm.controls['FlagType'].setValue(this.calculateFlagType(this.pages[pagesPageId].flag.text));
            this.flagModalForm.controls['FlagText'].setValue(this.pages[pagesPageId].flag.text);
            this.flagModalForm.controls['FlagCondition'].setValue(this.pages[pagesPageId].flag.condition);
            this.flagModalForm.controls['value'].setValue(this.pages[pagesPageId].flag.value);
        } else {
            this.minMaxOfValue = [-1000, 1000]
            this.typeOfFlagsCondition = []
            this.typeOfFlagsTexts = []
        }
    }

    deleteFlag(page) {
        this.flagDeleted = true
        this.flagModalForm.controls['FlagText'].setValue([], { onlySelf: true });
        this.flagModalForm.controls['FlagType'].setValue([], { onlySelf: true });
        this.flagModalForm.controls['FlagCondition'].setValue([], { onlySelf: true });
        this.flagModalForm.controls['value'].setValue([], { onlySelf: true });
    }

    calculateFlagType(flagText) {
        for (var item = 0; item < this.flagValueRestrictions.length; item++) {
            if (this.flagValueRestrictions[item].name == flagText) {
                return this.flagValueRestrictions[item].restriction
            }
        }
    }

    flagTypeChanged(value) {
        this.updateFlagConditionSelection(value);
        this.typeOfFlagsCondition = ["=", "<", ">", "!=", "<=", ">="];
        if (value == "Boolean") {
            this.typeOfFlagsCondition = ["=", "!="]
            this.minMaxOfValue = [0, 1]
        } else if (value == "Un-signed") {
            this.minMaxOfValue = [0, 1000]
        } else {
            this.minMaxOfValue = [-1000, 1000]
        }
        this.flagModalForm.controls['FlagText'].setValue(this.typeOfFlagsTexts[0], { onlySelf: true });
        this.flagModalForm.controls['FlagCondition'].setValue(this.typeOfFlagsCondition[0], { onlySelf: true });
        this.flagModalForm.controls['value'].setValue(0, { onlySelf: true });
    }

    updateFlagConditionSelection(value) {
        this.typeOfFlagsTexts = [];
        for (var item = 0; item < this.flagValueRestrictions.length; item++) {
            if (this.flagValueRestrictions[item].restriction == value) {
                this.typeOfFlagsTexts.push(this.flagValueRestrictions[item].name);
            }
        }
        this.flagModalForm.patchValue({ value: 0 })
    }


    Stations: IMultiSelectOption[];
    sectionSettings: IMultiSelectSettings;
    toggleEnableDestination() {
        if (this.isDestinationFlag) {
            this.isDestinationFlag = false;
        } else {
            this.isDestinationFlag = true;
        }
        console.log(this.isDestinationFlag)
    }
    checkFlags(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            var type = control.get("FlagType").value;
            var inValidFlag = false;
            var isDeleted = this.flagDeleted;
            var inValid = false;
            if (type == undefined || type.length == 0) {
                inValidFlag = true;
            }
            if (inValidFlag && !isDeleted) {
                inValid = true;
            }
            var value = control.get("value").value
            if (value < this.minMaxOfValue[0] || value > this.minMaxOfValue[1] || value == null) {
                inValid = true;
            }

            console.log({ inValidFlag: inValidFlag, isDeleted: isDeleted })
            return { inValid: inValid };
        };
    }
    checkProximity(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            var validTime = false;
            var validStation = false;
            var validSelection = false;
            var deleteInProgress = false;
            var valid = false;
            var stations = control.get("selectedStation").value;
            var time = control.get("time").value;
            var isDestination = control.get('isDestination').value;
            if (time > 0) {
                validTime = true;
            }
            if (isDestination) {
                validStation = true;
            } else {
                console.log(stations)
                if (stations !== undefined && stations.length > 0) {
                    validStation = true;
                }
            }
            if (validTime && validStation) {
                console.log(validTime)
                validSelection = true;
            }
            valid = validSelection
            if (this.proximityDeleted) {
                deleteInProgress = true;
                valid = true;
            }
            console.log({ validTime: validTime, validStation: validStation, validSelection: validSelection })
            return { validTime: validTime, validStation: validStation, validSelection: validSelection, deleteInProgress: deleteInProgress, valid: valid };
        };
    }
    displayTimeChanged(value, pageid) {
        var pagesPageId = this.calcPagesPageId(pageid);
        if (!this.pages[pagesPageId].isNew) {
            this.pages[pagesPageId].isEdited = true;
        }
        var numberValue = Number(value);
        if (numberValue > 999) {
            numberValue = 999;
        }
        this.pages[pagesPageId].displayDuration = numberValue;
        var isMandatory = this.checkPageIsMandatory(this.pages[pagesPageId])
        if (!isMandatory) {
            if (numberValue == 0) {
                this.pages[pagesPageId].enabled = false;
            }
            else if (numberValue < 0) {
                this.pages[pagesPageId].displayDuration = 0;
                this.pages[pagesPageId].enabled = false;
            }
            else {
                this.pages[pagesPageId].enabled = true;
            }
        }
        else {
            if (numberValue <= 1) {
                this.pages[pagesPageId].displayDuration = 1;
                console.log(this.pages[pagesPageId].displayDuration)
            }
        }
    }
    repeatTimeChanged(value) {
        var numberValue = Number(value);
        if (numberValue > 60) {
            numberValue = 60;
        }
        else if (numberValue < 0) {
            numberValue = 0;
        }
        this.repeatTimeValue = numberValue;
        this.repeatTimeModalForm.controls["repeatTime"].setValue(this.repeatTimeValue);
    }
    RepeatTimeColor(repeatTime) {
        if (repeatTime > 0) {
            return 'green'
        } else {
            return 'grey'
        }
    }
    pageDisplayTimeColor(pageid) {
        var pagesPageId = this.calcPagesPageId(pageid);
        if (this.pages[pagesPageId].enabled) {
            return 'white';
        } else {
            return 'grey'
        }
    }
    proximityColor(pageId) {
        var pagesPageId = this.calcPagesPageId(pageId);
        if (this.pages[pagesPageId].proximity == null || this.pages[pagesPageId].proximity.time == 0 || this.pages[pagesPageId].proximity.isDeleted) {
            return 'grey';
        } else {
            return 'green';
        }
    }
    flagCreated(pageId) {
        var pagesPageId = this.calcPagesPageId(pageId);
        if (this.pages[pagesPageId].flag == undefined || this.pages[pagesPageId].flag == null || this.pages[pagesPageId].flag.isDeleted) {
            return 'grey';
        } else {
            return 'green'
        }
    }

    flagCreatedNewPage() {
        if (this.newPage.flag == undefined || this.newPage.flag == null || this.selectedType != 'Target Page') {
            return 'grey';
        } else {
            return 'green'
        }
    }

    pageFlagText(page) {
        if (page.flag != null)
            return page.flag.text;
        else
            return "flag";
    }

    toggleEnable(pageId) {
        var pagesPageId = this.calcPagesPageId(pageId);
        if (this.pages[pagesPageId].enabled) {
            this.pages[pagesPageId].enabled = false;
        } else {
            if (this.pages[pagesPageId].displayDuration == 0) {
                this.pages[pagesPageId].displayDuration = 1;
            }
            this.pages[pagesPageId].enabled = true;
        }
    }
}


function __indexOf(collection, node) {
    return Array.prototype.indexOf.call(collection, node);
};

function __isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
    const { top, bottom, left, right } = dropList.element.nativeElement.getBoundingClientRect();
    return y >= top && y <= bottom && x >= left && x <= right;
}
